import React from "react";
import MinMaxSliderFilter from "./min-max/MinMaxSliderFilter";
import DefaultMCFilter from "./multiple-choice/DefaultMCFilter";
import CarTypeFilterEnum from "./car-type/CarTypeFilter";
import PriceFilter from "./price/PriceFilter";
import * as Styles from "./styles/FilterWrapperStyles";
import { isCarType, isMinMaxSlider, isMultipleChoice, isPrice } from "../../../utils/filters";
import { ModelFilterId } from "../../../utils/constants/filterConstants";

const FilterWrapper = (props: { filterId: ModelFilterId }): JSX.Element => {
    const { filterId } = props;

    let component = null;
    // Unique filter components.

    if (isCarType(filterId)) component = <CarTypeFilterEnum />;
    if (isPrice(filterId)) component = <PriceFilter />;

    // Default filter components.
    if (!component) {
        const shouldMergeByLabel = [ModelFilterId.Transmission].includes(filterId);
        if (isMinMaxSlider(filterId)) component = <MinMaxSliderFilter filterId={filterId} />;
        if (isMultipleChoice(filterId))
            component = <DefaultMCFilter filterId={filterId} mergeByLabel={shouldMergeByLabel} />;
    }

    return <Styles.Wrapper>{component}</Styles.Wrapper>;
};

export default FilterWrapper;
