import React from "react";
import { useModelFilterLabel } from "../../../../utils/labels";
import CarTypeMCFilter from "./CarTypeMCFilter";
import { CarTypeFilterEnum } from "../../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

const CarTypeFilter = (): JSX.Element => {
    const [carCategoryLabel, fuelTypeLabel] = useModelFilterLabel(["category", "fuel"]);

    return (
        <>
            <CarTypeMCFilter carTypeFilterId={CarTypeFilterEnum.CarType} label={carCategoryLabel} />
            <CarTypeMCFilter carTypeFilterId={CarTypeFilterEnum.FuelType} label={fuelTypeLabel} />
        </>
    );
};

export default CarTypeFilter;
