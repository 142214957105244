import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import ActiveFilterSliderPill from "../slider/ActiveFilterSliderPill";
import { ModelFilterLabel } from "../../../../utils/labels";
import * as Styles from "./styles/ConfigurationHeaderContainerStyle";
import FilterPopup from "./FilterPopup";
import SortFilters from "../top-bar/SortFilters";
import { trackOpenFilter } from "../../../../utils/tracking";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";
import { clearAllFilters } from "../../../../utils/filters";
import { Backdrop } from "../../../../../../../common-deprecated/styles/v2/toyota/components/Backdrop";
import ButtonTertiary from "../../../../../../../common-deprecated/components/aem/toyota/ButtonTertiary";

// This is configurable in car-filter, keep hardcoded in model-filter for now.
const mainNewCarFilters: ModelFilterId[] = [ModelFilterId.CarType, ModelFilterId.Price];

// The ConfigurationHeaderContainer contains the pills on desktop which are in the header. Here the ActiveFilerSliderPill's are used.
// On mobile the ActiveFilterSliderContainer is used inside the FilterSidebar
const ConfigurationHeaderContainer = (): JSX.Element => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const modelFilters = useModelFilterSelector((state) => state.modelFilters);
    const activeFilterCount = Object.values(ModelFilterId).filter((filterId) => modelFilters[filterId].active).length;

    const containerRef = useRef<HTMLDivElement>(null);

    // If empty there is no filter popup.
    const [activeFilter, setActiveFilter] = useState<ModelFilterId | null>(null);

    // FilterPosition contains the original position of the filter popup.
    // FilterPopup component will calculate the proper position taking into account its own width.
    const [filterPosition, setFilterPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const filterPills = Object.values(ModelFilterId)
        // Only render filters that are active or part of the main set of filters.
        .filter(
            (filterId) =>
                modelFilters[filterId].active || (mainNewCarFilters.includes(filterId) && modelFilters[filterId].show),
        )
        .sort((a, b) => modelFilters[a].index - modelFilters[b].index)
        .map((filterId) => (
            <ActiveFilterSliderPill
                key={filterId}
                filterId={filterId}
                activeFilter={activeFilter}
                showFilter={(pos) => {
                    trackOpenFilter(modelFilters[filterId].label);
                    setActiveFilter(filterId);
                    setFilterPosition(pos);
                }}
            />
        ));

    return (
        <Styles.Wrapper ref={containerRef}>
            <Styles.Pills>
                {filterPills}
                <SortFilters />
            </Styles.Pills>
            {activeFilter && (
                <>
                    <FilterPopup
                        filterId={activeFilter}
                        containerRef={containerRef}
                        originalPos={filterPosition}
                        closeFilter={() => setActiveFilter(null)}
                    />
                    <Backdrop />
                </>
            )}
            {activeFilterCount > 0 && (
                <>
                    <Styles.MobileFooter>
                        <Styles.FilterCount>
                            <ModelFilterLabel label="activeFilterCount" params={{ value: activeFilterCount }} />
                        </Styles.FilterCount>
                        <ButtonTertiary stretchedLink onClick={() => clearAllFilters(dispatch, modelFilters)}>
                            <ModelFilterLabel label="clearAllFilters" />
                        </ButtonTertiary>
                    </Styles.MobileFooter>
                    <Styles.DesktopFooter>
                        <ButtonTertiary stretchedLink onClick={() => clearAllFilters(dispatch, modelFilters)}>
                            <ModelFilterLabel label="clearAllFilters" />
                        </ButtonTertiary>
                    </Styles.DesktopFooter>
                </>
            )}
        </Styles.Wrapper>
    );
};

export default ConfigurationHeaderContainer;
