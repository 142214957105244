import React from "react";
import * as Styles from "../../../../../../common-deprecated/components/aem/toyota/banner/styles/BannerStyles";
import Banner from "../../../../../../common-deprecated/components/aem/toyota/banner/Banner";
import Spacer from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Spacer";
import { useModelFilterSelector } from "../../../redux/store";
import { useModelFilterLabel } from "../../../utils/labels";

export const BusinessLeasingBanner: React.FC = () => {
    const show = useModelFilterSelector(
        (state) =>
            state.modelFilterSettings.enableB2B &&
            state.modelResults.results.some(({ availableForBusinessLeasing }) => availableForBusinessLeasing),
    );
    const b2bAvailableLabel = useModelFilterLabel("b2bAvailable");
    return (
        <Spacer p={{ _: "0 24px 24px", xl: "0 12px 14px" }}>
            <Banner bgColor="kinto" show={show}>
                <Styles.Body dangerouslySetInnerHTML={{ __html: b2bAvailableLabel }} />
            </Banner>
        </Spacer>
    );
};
