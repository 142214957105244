import React from "react";
import { useDispatch } from "react-redux";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import MCFilterView from "../multiple-choice/MCFilterView";
import { setCarType } from "../../../../redux/actions/ModelFiltersActions";
import { CarTypeFilterEnum } from "../../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";
import * as Styles from "./styles/CarTypeFilterStyles";

type UsedCarTypeMCFilterType = {
    label?: string;
    carTypeFilterId: CarTypeFilterEnum.FuelType | CarTypeFilterEnum.CarType;
};

// This component is a special version of the multiple choice filter, it uses a different data source compared to the default.
const CarTypeMCFilter = ({ carTypeFilterId, label }: UsedCarTypeMCFilterType): JSX.Element | null => {
    const filterValues = useModelFilterSelector((state) =>
        state.modelFilters[ModelFilterId.CarType][carTypeFilterId].filter((filter) => filter.resultCount),
    );
    const dispatch = useDispatch<ModelFilterDispatchType>();

    if (!filterValues.length) return null;

    const onSelectValue = (valueId: string, selected: boolean): void => {
        dispatch(setCarType(ModelFilterId.CarType, carTypeFilterId, [valueId], selected));
    };

    return (
        <>
            {label && (
                <Styles.Label role="heading" aria-level={5}>
                    {label}
                </Styles.Label>
            )}
            <MCFilterView
                id={`${ModelFilterId.CarType}-${carTypeFilterId}`}
                columnWidth={1}
                values={filterValues}
                selectValue={onSelectValue}
            />
        </>
    );
};

export default CarTypeMCFilter;
