import React from "react";
import { useDispatch } from "react-redux";
import * as Type from "../../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import * as Styles from "./styles/NoResultsStyles";
import { clearAllFilters } from "../../../utils/filters";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../redux/store";
import { useModelFilterLabel } from "../../../utils/labels";
import ButtonTertiary from "../../../../../../common-deprecated/components/aem/toyota/ButtonTertiary";

const NoResults = (): JSX.Element => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const modelFilters = useModelFilterSelector((state) => state.modelFilters);
    const clearAllFiltersHandler = (): void => {
        clearAllFilters(dispatch, modelFilters);
    };

    const titleLabel = useModelFilterLabel("noResultsTitle");
    const descriptionLabel = useModelFilterLabel("noResultsDescription");
    const buttonLabel = useModelFilterLabel("clearAllFilters");

    return (
        <Styles.Wrapper>
            <Styles.NoResultTextWrapper>
                <Type.H2 headingType="gamma">{titleLabel}</Type.H2>
                <Type.Text headingType="base">{descriptionLabel}</Type.Text>
            </Styles.NoResultTextWrapper>
            <ButtonTertiary onClick={clearAllFiltersHandler}>{buttonLabel}</ButtonTertiary>
        </Styles.Wrapper>
    );
};

export default NoResults;
