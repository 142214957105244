import React from "react";
import * as Style from "./styles/FilterDescriptionStyles";

type FilterDescriptionType = {
    minToMax: boolean;
    minText: string;
    minTextDesc?: string;
    maxText: string;
    maxTextDesc?: string;
};

const FilterDescription = (props: FilterDescriptionType): JSX.Element => {
    const { maxText, maxTextDesc, minText, minTextDesc, minToMax } = props;

    return (
        <Style.Wrapper>
            <Style.Min>
                <Style.Title dangerouslySetInnerHTML={{ __html: minToMax ? minText : maxText }} />
                {!!(minToMax ? minTextDesc : maxTextDesc) && (
                    <Style.Subtitle>{minToMax ? minTextDesc : maxTextDesc}</Style.Subtitle>
                )}
            </Style.Min>

            <Style.Max>
                <Style.Title dangerouslySetInnerHTML={{ __html: minToMax ? maxText : minText }} />
                {!!(minToMax ? maxTextDesc : minTextDesc) && (
                    <Style.Subtitle>{minToMax ? maxTextDesc : minTextDesc}</Style.Subtitle>
                )}
            </Style.Max>
        </Style.Wrapper>
    );
};

export default FilterDescription;
