import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import { setPrice, setPriceActive } from "../../../../redux/actions/ModelFiltersActions";
import { useModelFilterLabel } from "../../../../utils/labels";
import * as Flex from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import * as FinanceToggleStyles from "../../../../../../../common-deprecated/styles/v2/toyota/components/FinanceToggle";
import * as Styles from "./styles/PriceStyles";
import {
    SliderButtonType,
    BUTTON_MAX,
    BUTTON_MIN,
    SLIDER_INCREASING,
} from "../../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import ButtonSlider from "../../../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSlider";
import FilterDescription from "../../../../../../../common-deprecated/features/filters/components/toyota/filters/FilterDescription/FilterDescription";
import { FinanceOptionType } from "../../../../../../../common-deprecated/types/CommonTypes";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";
import { hasDualCurrency } from "../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import ButtonSliderRTL from "../../../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSliderRTL";
import { useCommonSelector } from "../../../../../../../common-deprecated/redux/commonStore";
import * as commonSettingUtils from "../../../../../../../common-deprecated/settings/utils/commonSettingUtils";
import { getValueLabelRTLWrapper } from "../../../../../../../shared-logic/features/filters/utils/helpers";
import usePriceFilter from "../../../../hooks/usePriceFilter";

const PriceFilter = (): JSX.Element => {
    const dispatch = useDispatch<ModelFilterDispatchType>();

    const hasSecondaryPrice = useModelFilterSelector((state) => hasDualCurrency(state.commonSettings));
    const priceFilter = useModelFilterSelector((state) => state.modelFilters[ModelFilterId.Price]);
    const activeFilter = useModelFilterSelector((state) =>
        state.modelFilters[ModelFilterId.Price].monthly.active ? "monthly" : "cash",
    );
    const isRTL = useCommonSelector((state) => commonSettingUtils.isRTL(state.commonSettings));
    const { showToggle, activeFinanceOption, formatPrice } = usePriceFilter(priceFilter, activeFilter);
    const [cashLabel, monthlyLabel] = useModelFilterLabel(["priceCash", "priceMonthly"]);

    const deprecatedTitleLabel = useModelFilterLabel("filterLabelPrice");
    const titleLabel = priceFilter.titleLabel || deprecatedTitleLabel;

    const { cash, monthly } = priceFilter;
    const {
        currentMinValue,
        currentMaxValue,
        minValue,
        maxValue,
        step,
        valueLabelConfig,
        customMinValue,
        customMaxValue,
    } = activeFinanceOption === "monthly" ? monthly : cash;
    const { minValueText, maxValueText, value } = valueLabelConfig;

    const clippedMin = customMinValue || minValue;
    const clippedMax = customMaxValue || maxValue;

    const displayMaxValue = Math.min(currentMaxValue, clippedMax);
    const displayMinValue = Math.max(currentMinValue, clippedMin);

    const setValue = (type: SliderButtonType, val: number, valueFinanceOption: FinanceOptionType): void => {
        dispatch(setPrice(ModelFilterId.Price, type, val, valueFinanceOption));
        dispatch(setPriceActive(ModelFilterId.Price, valueFinanceOption));
    };

    const setValueFn = useCallback(
        (newValue: number, type: SliderButtonType) => {
            if (type === BUTTON_MAX) {
                setValue("max", newValue === clippedMax ? maxValue : newValue, activeFinanceOption);
            }
            if (type === BUTTON_MIN) {
                setValue("min", newValue === clippedMin ? minValue : newValue, activeFinanceOption);
            }
        },
        [activeFinanceOption, clippedMax, clippedMin, maxValue, minValue],
    );

    // The secondary value will only ever be true for dual currency
    const getValueLabelFn = useCallback(
        (secondary: boolean) =>
            (max: number, min: number = 0): string => {
                const minVal = isRTL ? max : min;
                const maxVal = isRTL ? min : max;

                // Fallback for old labels containing {value}.
                // According to the new setup {min} and {max} should be used but some countries still have the old value.
                return (
                    value.includes("{value}")
                        ? value.replace(
                              "{value}",
                              `${formatPrice(minVal, secondary)} - ${formatPrice(maxVal, secondary)}`,
                          )
                        : value
                              .replace("{min}", formatPrice(minVal, secondary))
                              .replace("{max}", formatPrice(maxVal, secondary))
                ).replace(/&nbsp;/g, " ");
            },
        [value],
    );

    const [valueLabel, setValueLabel] = useState<string>(
        (isRTL ? getValueLabelRTLWrapper(minValue, maxValue, getValueLabelFn(false)) : getValueLabelFn(false))(
            displayMaxValue,
            displayMinValue,
        ),
    );
    // Dual currency
    const [secondaryValueLabel, setSecondaryValueLabel] = useState<string>(
        getValueLabelFn(true)(displayMaxValue, displayMinValue),
    );

    return (
        <Styles.Wrapper>
            {showToggle && (
                <Flex.Col width={1} px={0} mb={2}>
                    <FinanceToggleStyles.Wrapper isMonthlyActive={activeFilter === "monthly"} fullWidth>
                        <FinanceToggleStyles.ToggleItem
                            onClick={() => dispatch(setPriceActive(ModelFilterId.Price, "cash"))}
                        >
                            {cashLabel}
                        </FinanceToggleStyles.ToggleItem>
                        <FinanceToggleStyles.ToggleItem
                            onClick={() => dispatch(setPriceActive(ModelFilterId.Price, "monthly"))}
                        >
                            {monthlyLabel}
                        </FinanceToggleStyles.ToggleItem>
                    </FinanceToggleStyles.Wrapper>
                </Flex.Col>
            )}
            <Flex.Col width={1} px={0} mb={2}>
                <Styles.Result>
                    {titleLabel && <Styles.Label>{titleLabel}</Styles.Label>}
                    <Styles.Value hasLongText={valueLabel.length >= 30}>{valueLabel}</Styles.Value>
                    {hasSecondaryPrice && (
                        <Styles.Value hasLongText={secondaryValueLabel.length >= 30}>
                            {secondaryValueLabel}
                        </Styles.Value>
                    )}
                </Styles.Result>
            </Flex.Col>
            <Flex.Col width={1} px={0}>
                {isRTL ? (
                    <ButtonSliderRTL
                        minValue={clippedMin}
                        maxValue={clippedMax}
                        step={step}
                        currentMaxValue={displayMaxValue}
                        currentMinValue={displayMinValue}
                        setValue={setValueFn}
                        enableMinValue
                        order={SLIDER_INCREASING}
                        getValueLabel={getValueLabelFn(false)}
                        setValueLabel={setValueLabel}
                        getSecondaryValueLabel={hasSecondaryPrice ? getValueLabelFn(true) : undefined}
                        setSecondaryValueLabel={hasSecondaryPrice ? setSecondaryValueLabel : undefined}
                        filterName={titleLabel}
                    />
                ) : (
                    <ButtonSlider
                        minValue={clippedMin}
                        maxValue={clippedMax}
                        step={step}
                        currentMaxValue={displayMaxValue}
                        currentMinValue={displayMinValue}
                        setValue={setValueFn}
                        enableMinValue
                        order={SLIDER_INCREASING}
                        getValueLabel={getValueLabelFn(false)}
                        setValueLabel={setValueLabel}
                        getSecondaryValueLabel={hasSecondaryPrice ? getValueLabelFn(true) : undefined}
                        setSecondaryValueLabel={hasSecondaryPrice ? setSecondaryValueLabel : undefined}
                        filterName={titleLabel}
                    />
                )}
                <FilterDescription
                    minText={minValueText.replace("{value}", formatPrice(clippedMin))}
                    maxText={maxValueText.replace("{value}", formatPrice(clippedMax))}
                    minToMax
                />
                {hasSecondaryPrice && (
                    <FilterDescription
                        minText={minValueText.replace("{value}", formatPrice(clippedMin, true))}
                        maxText={maxValueText.replace("{value}", formatPrice(clippedMax, true))}
                        minToMax
                    />
                )}
            </Flex.Col>
        </Styles.Wrapper>
    );
};

export default PriceFilter;
