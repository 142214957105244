import styled, { css } from "styled-components";
import { getBreakpoint, getDirection } from "../../../../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { IconWrapper } from "../../../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import * as PillStyles from "../../../../../../../../common-deprecated/styles/v2/toyota/components/Pill";

export const Slider = styled.div`
    flex: 1;
    margin-top: 16px;
    overflow: hidden;
`;

export const SliderViewport = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    margin: 0 auto;
    padding: 0 ${theme.spacer / 2}px;
    overflow-y: hidden;

    @media ${getBreakpoint("up", "lg")} {
        height: auto;
        padding: 0;
    }
`;

type TrackType = { width?: number; left: number; enableTransition: boolean };
export const Track = styled.div.attrs<TrackType>((props) => ({
    style: { transform: `translateX(${props.left}px)` },
}))<TrackType>`
    display: flex;
    left: 0;
    flex-direction: row;
    width: ${(props) => props.width}px;
    ${(props) =>
        props.enableTransition &&
        css`
            transition: transform 0.5s;
        `};

    @media ${getBreakpoint("up", "lg")} {
        position: relative;
        flex-wrap: wrap;
        width: 100%;
    }
`;

export const Prerender = styled.div`
    height: 0;
    opacity: 0;
`;

export const Wrapper = styled.div`
    padding: ${theme.space[1]}px ${theme.space[2]}px;
`;

export const PillWrapper = styled.div<{ isSelected?: boolean }>`
    display: inline-block;
    position: relative;
    max-width: 100%;
    padding-${getDirection("left")}: 0;
    padding-${getDirection("right")}: ${theme.space[1]}px;
    z-index: ${({ isSelected }) => (isSelected ? 1061 : "auto")};

    @media ${getBreakpoint("up", "lg")} {
        max-width: none;
        padding-bottom: ${theme.space[1]}px;
    }

    ${PillStyles.Wrapper} {
        margin: 5px 0;

        .btn-text {
            min-width: 50px;
        }
    }

    ${IconWrapper} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const PillText = styled.span<{ strikethrough: boolean }>`
    color: ${theme.colors.primaryWhite};
    ${({ strikethrough }) =>
        strikethrough &&
        css`
            text-decoration: line-through;
        `};
`;
