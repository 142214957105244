import React from "react";
import * as FilterCheckInputStyle from "../../common/styles/FilterCheckInputStyles";
import { MultipleChoiceValueType } from "../../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import FilterCheckInput from "../../common/FilterCheckInput";

type MCFilterViewType<T extends MultipleChoiceValueType> = {
    values: T[];
    selectValue: (valueId: string, selected: boolean) => void;
    columnWidth?: number;
    id: string; // Id is used to add a unique id to the input fields as it is possible that some value ides overlap.
};

/**
 * View used for rendering multiple choice options. Note that this is used in other places than the default multiple choice filters.
 */
const MCFilterView = <T extends MultipleChoiceValueType>(props: MCFilterViewType<T>): JSX.Element => {
    const { values, selectValue, columnWidth, id } = props;

    return (
        <FilterCheckInputStyle.List>
            {values
                .sort((a, b) => {
                    // Put values with no results last, otherwise keep the sort order unchanged.
                    if (a.resultCount === 0 && b.resultCount !== 0) return 1;
                    else if (a.resultCount !== 0 && b.resultCount === 0) return -1;
                    else return 0;
                })
                .map((val) => (
                    <FilterCheckInputStyle.ListItem width={columnWidth} key={val.id}>
                        <FilterCheckInput
                            checked={val.selected}
                            label={val.label}
                            onChange={() => selectValue(val.id, !val.selected)}
                            disabled={val.resultCount === 0}
                            id={`${id}-${val.id}`}
                            resultCount={val.resultCount}
                        />
                    </FilterCheckInputStyle.ListItem>
                ))}
        </FilterCheckInputStyle.List>
    );
};

export default MCFilterView;
