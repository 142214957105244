import styled, { css } from "styled-components";
import { width, WidthProps } from "styled-system";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";
import * as CheckboxStyle from "../../../../../../../common-deprecated/styles/v2/toyota/components/Input";
import { focusStyle } from "../../../../../../../common-deprecated/styles/v2/toyota/utilities/Focus";

// List
export const List = styled.ul.attrs(() => ({
    className: "list-unstyled",
}))`
    padding-${getDirection("left")}: 0;
    &:not(:last-of-type) {
        margin-bottom: ${theme.space[4]}px !important; /* Added an !important to override Foundation team CSS */
    }
`;

export const ListItem = styled.li<WidthProps>`
    display: flex;
    align-items: flex-start;
    margin-bottom: ${theme.space[3]}px !important; /* Added an !important to override Foundation team CSS */
    ${width};

    &:last-child {
        margin-bottom: 0px !important;
    }
`;

// Checkbox style
// eslint-disable-next-line prefer-destructuring
export const Wrapper = styled(CheckboxStyle.Wrapper)`
    ${focusStyle};
`;

export const Checkbox = styled(CheckboxStyle.Checkbox)<{ disabled?: boolean }>`
    ${(props) =>
        props.disabled &&
        css`
            color: ${theme.colors.grey3};
            cursor: default;
        `}
`;

export const Counter = styled.span.attrs(() => ({
    className: "t-nano-text",
}))`
    display: inline-block;
    margin-${getDirection("left")}: ${theme.space[2]}px;
    color: ${theme.colors.grey4};
`;
