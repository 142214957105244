import React, { useRef } from "react";
import * as Styles from "./styles/ActiveFilterSliderStyles";
import ActiveFilterSliderPill from "./ActiveFilterSliderPill";
import useActiveFilterSlider from "../../../../../../../shared-logic/features/filters/hooks/useActiveFilterSlider";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";

type ActiveFilterSliderType = { activeFilters: ModelFilterId[]; activeFiltersKey: string };

/**
 * Slider that contains the small slider pills for active filters.
 */
const ActiveFilterSlider = ({ activeFilters, activeFiltersKey }: ActiveFilterSliderType): JSX.Element => {
    const viewportRef = useRef<HTMLDivElement>(null);
    const prerenderRef = useRef<HTMLDivElement>(null);
    const { updateWidth, sliderFilters } = useActiveFilterSlider(
        prerenderRef,
        viewportRef,
        activeFilters,
        activeFiltersKey,
    );
    // Retrieve all props for slider usage.
    return (
        <>
            <Styles.Wrapper>
                {(sliderFilters as ModelFilterId[]).map((filterId) => (
                    <ActiveFilterSliderPill key={filterId} filterId={filterId} />
                ))}
            </Styles.Wrapper>

            {/* Hidden and heightless container used to render the slider items to get their width. */}
            <Styles.Prerender ref={prerenderRef}>
                {activeFilters.map((filterId) => (
                    <ActiveFilterSliderPill key={filterId} filterId={filterId} update={updateWidth} />
                ))}
            </Styles.Prerender>
        </>
    );
};

export default ActiveFilterSlider;
