import styled from "styled-components";
import * as BackgroundStyles from "../../../common/styles/BackgroundStyles";
import { getDirection } from "../../../../../../../../common-deprecated/themes/common";
import { focusStyle } from "../../../../../../../../common-deprecated/styles/v2/toyota/utilities/Focus";

/**
 * Shared styles for a "popup list" which contains a clickable list of button options.
 */
export const Wrapper = styled(BackgroundStyles.Shadow)`
    position: absolute;
    right: 0;
    min-width: 256px;
    z-index: ${(props) => props.theme.zIndex.popup};
`;

export const Button = styled.button`
    width: 100%;
    min-height: 50px;
    margin: 0;
    padding: 0;
    padding-${getDirection("left")}: 16px;
    text-align: ${getDirection("left")};
    color: ${(props) => props.theme.color.grey4};
    background: none;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.color.grey1};
    ${focusStyle};
`;

export const Item = styled.div``;

export const ItemRow = styled.div`
    &:last-child {
        ${Button} {
            border-bottom: none;
        }
    }
`;
