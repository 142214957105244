import styled from "styled-components";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getBreakpoint, getDirection } from "../../../../../../../../common-deprecated/themes/common";

export const Wrapper = styled.div`
    display: inline-block;
    position: relative;
    align-self: center;
    max-width: 100%;

    @media ${getBreakpoint("down", "lg")} {
        display: flex;
        width: 100%;
    }
`;

export const Pill = styled.div`
    @media ${getBreakpoint("down", "lg")} {
        display: none;
    }
`;

export const Popup = styled.div<{ top?: number; isFixed?: boolean }>`
    position: fixed;
    top: ${({ top, isFixed }) =>
        (top || 0) + (isFixed ? 76 : theme.space[6])}px; /* TopOffset of sticky bar + height of sticky bar */
    ${getDirection("left")}: ${theme.space[2]}px;
    ${getDirection("right")}: ${theme.space[2]}px;
    min-width: 50vw;
    background-color: ${theme.colors.primaryWhite};
    border: 1px solid ${theme.colors.grey2};
    border-radius: 8px;
    z-index: ${theme.zIndex.popover};

    @media ${getBreakpoint("up", "md")} {
        ${getDirection("left")}: auto;
    }

    @media ${getBreakpoint("up", "lg")} {
        position: absolute;
        top: 66px; /* 40 (height button) + 24 (spacing) */
        min-width: 256px;
        ${getDirection("left")}: auto;
        ${getDirection("right")}: 8px;
    }
`;
