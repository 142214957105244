import styled from "styled-components";
import { ColorType, theme } from "../../../../../styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div<{ backgroundColor: ColorType }>`
    display: flex;
    padding: ${theme.space[3] / 2}px ${theme.space[3] / 2}px 14px;
    gap: ${theme.space[1] / 2}px;
    background-color: ${({ backgroundColor }) => backgroundColor && theme.colors[backgroundColor]};
    border-radius: 4px;
`;

export const Body = styled.div`
    flex-grow: 1;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    color: ${theme.colors.primaryWhite};

    & > b {
        font-family: ${theme.fonts.semiBold};
    }
`;
