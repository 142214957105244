import React, { useEffect, useRef } from "react";
import { useModalMap } from "../../../../../common-deprecated/containers/ModalProvider";
import { aemToyotaCommonModalMap } from "../../../../../common-deprecated/components/modals/aem/modals";
import * as ConfigurationHeaderStyles from "./active-filters/configuration-header/styles/ConfigurationHeaderContainerStyle";
import ModelResultsContainer from "./model-results/ModelResultsContainer";
import FilterSidebar from "./filters/FilterSidebar";
import StaticFilterBar from "./active-filters/top-bar/StaticFilterBar";
import useFixedFilterBar from "../../../../../shared-logic/features/filters/hooks/useFixedFilterBar";
import { FILTER_BAR_HEIGHT } from "./active-filters/top-bar/styles/StaticFilterBarStyles";
import FilterResultCount from "./active-filters/top-bar/FilterResultCount";
import ConfigurationHeaderContainer from "./active-filters/configuration-header/ConfigurationHeaderContainer";
import * as ModelResultStyles from "./model-results/styles/ModelResultStyles";
import useModelFilter from "../../hooks/useModelFilter";
import useStatus from "../../../../../common-deprecated/hooks/useStatus";
import { getRetailerDebugToggleLink } from "../../../../../common-deprecated/utils";
import { useModelFilterSelector } from "../../redux/store";
import { updateTrackingLayerVersioning } from "../../utils/tracking";

const ModelFilter = (): JSX.Element => {
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);

    const resultsContainerRef = useRef<HTMLDivElement>(null);
    const isFixed = useFixedFilterBar(resultsContainerRef, FILTER_BAR_HEIGHT);

    useModelFilter();
    useModalMap(aemToyotaCommonModalMap);
    useStatus(commonSettings, "Model Filter", [getRetailerDebugToggleLink()]);
    useEffect(() => {
        updateTrackingLayerVersioning();
    }, []);

    return (
        <>
            <StaticFilterBar isFixed={isFixed} />

            <ConfigurationHeaderStyles.Viewport>
                <ConfigurationHeaderContainer />
            </ConfigurationHeaderStyles.Viewport>

            <ModelResultStyles.Container>
                <ModelResultStyles.Header>
                    <FilterResultCount show={!isFixed} sruAccessible={-1} hasHeading />
                </ModelResultStyles.Header>

                <ModelResultStyles.Main>
                    <FilterSidebar isFixed={isFixed} />
                    <ModelResultsContainer />
                </ModelResultStyles.Main>
            </ModelResultStyles.Container>
        </>
    );
};

export default ModelFilter;
