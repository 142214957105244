import styled from "styled-components";
import { getBreakpoint, getDirection } from "../../../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as SidebarWrapperStyles from "../../../../../../../common-deprecated/components/styles/SidebarWrapperStyles";
import * as FilterList from "./FilterListStyles";
import * as CarTypeFilter from "../car-type/styles/CarTypeFilterStyles";
import * as ConfigurationHeaderStyles from "../../active-filters/configuration-header/styles/ConfigurationHeaderContainerStyle";

const sidebarWidth = 332;

export const SidebarContainer = styled.div<{ show?: boolean; absolute: boolean; top: number; isFixed: boolean }>`
    position: ${(props) => (props.absolute ? "absolute" : "relative")};
    ${getDirection("right")}: 0px;
    width: ${sidebarWidth - 1}px; /* width sidebar - 1px from border */
    height: 100%;
    padding: 0;
    background-color: ${theme.colors.primaryWhite};
    z-index: 900; /* Fixed to 900 to prevent overlapping with usabilla button */

    ${FilterList.Header} {
        margin: 0 -${theme.spacer / 2}px;
        padding: ${theme.space[2]}px ${theme.space[3]}px;
        border-bottom: 1px solid ${theme.colors.grey2};
        @media ${getBreakpoint("up", "lg")} {
            margin: 0;
            padding: ${theme.space[2]}px 0;
            border-bottom: 1px solid transparent;
        }
    }

    ${CarTypeFilter.SearchTextButton} {
        @media ${getBreakpoint("up", "lg")} {
            background-color: ${theme.colors.grey1};
        }
    }

    ${SidebarWrapperStyles.Wrapper} {
        @media ${getBreakpoint("up", "xs")} {
            width: 100%;
        }

        @media ${getBreakpoint("up", "lg")} {
            width: ${sidebarWidth}px;
        }
    }

    @media ${getBreakpoint("down", "md")} {
        position: relative;
        ${getDirection("left")}: 0;
    }

    @media ${getBreakpoint("down", "lg")} {
        width: 100vw;
    }

    ${ConfigurationHeaderStyles.MobileFooter} {
        padding-bottom: ${theme.space[2]}px;

        &::after {
            position: absolute;
            bottom: 0;
            left: 24px;
            right: 24px;
            width: calc(100% - 48px);
            height: 1px;
            content: "";
            background-color: ${theme.colors.grey2};
        }
    }
`;

// Mobile header should stay fixed while content below should be scrollable
const headerHeight = 72;
export const MobileHeader = styled.div`
    display: none;
    border-bottom: 1px solid ${theme.colors.grey2};

    @media ${getBreakpoint("down", "lg")} {
        display: block;
        position: fixed;
        top: 0;
        ${getDirection("left")}: 0;
        width: 100vw;
        height: ${headerHeight}px;
        background: ${theme.colors.primaryWhite};
        border-bottom: 0;
        z-index: ${theme.zIndex.overlay};
    }
`;

export const Wrapper = styled.div<{
    show: boolean;
    navOffset: number;
    disableTransition: boolean;
    reachOutMobile?: number;
}>`
    position: relative;
    flex: unset;
    align-self: stretch;
    width: ${(props) => (props.show ? sidebarWidth : 0)}px;
    border-${getDirection("right")}: 1px solid ${({ show }) => (show ? theme.colors.grey2 : "transparent")};
    transition: ${(props) => (props.disableTransition ? "none" : "padding 0.3s, width 0.3s")};

    @media ${getBreakpoint("down", "lg")} {
        display: ${(props) => (props.show ? "block" : "none")};
        position: fixed;
        top: ${headerHeight}px;
        ${getDirection("left")}: 0;
        width: 100%;
        height: ${({ reachOutMobile }) =>
            reachOutMobile ? `calc(100% - ${headerHeight}px - ${reachOutMobile}px)` : `calc(100% - ${headerHeight}px)`};
        background: ${theme.colors.primaryWhite};
        border-${getDirection("right")}: none;
        transition: none;
        overflow-x: hidden;
        overflow-y: scroll;
        z-index: ${theme.zIndex.overlay};
    }
`;
