import React from "react";
import * as Styles from "./styles/FilterCheckInputStyles";

type FilterCheckInput = {
    checked: boolean;
    label?: string | JSX.Element;
    onChange?: () => void;
    disabled?: boolean;
    id: string;
    resultCount?: number;
};

const FilterCheckInput = ({ checked, label, onChange, disabled, id, resultCount }: FilterCheckInput): JSX.Element => (
    <Styles.Wrapper variant="checkbox">
        <input type="checkbox" checked={checked} id={id} onChange={onChange} readOnly={!onChange} disabled={disabled} />
        <Styles.Checkbox disabled={disabled} htmlFor={id} id={`label-${id}`}>
            {label}
            {typeof resultCount !== "undefined" && <Styles.Counter>{resultCount}</Styles.Counter>}
        </Styles.Checkbox>
    </Styles.Wrapper>
);

export default FilterCheckInput;
