import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import SidebarWrapper from "../../../../../../common-deprecated/components/SidebarWrapper";
import FilterList from "./FilterList";
import * as Styles from "./styles/FilterSidebarStyles";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../redux/store";
import { showMoreFilters as showMoreFilterAction } from "../../../redux/actions/ModelFiltersActions";
import { FILTER_BAR_HEIGHT } from "../active-filters/top-bar/styles/StaticFilterBarStyles";
import FilterPopupHeader from "../active-filters/top-bar/FilterPopupHeader";
import useFilterSidebar from "../../../../../../shared-logic/features/filters/hooks/useFilterSidebar";
import ActiveFilterSliderContainer from "../active-filters/slider/ActiveFilterSliderContainer";
import { useBreakpoint } from "../../../../../../common-deprecated/themes/common";
import { getMobileReachoutModalSize } from "../../../../../../common-deprecated/utils";

type FilterSidebarType = {
    isFixed: boolean;
};

const FilterSidebar = ({ isFixed }: FilterSidebarType): JSX.Element => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const hasMobileSidebar = useBreakpoint("down", "lg");
    const showMoreFilters = useModelFilterSelector((state) => state.modelFilters.showMoreFilters);
    const [componentInitialized, setComponentInitialized] = useState(false);
    const [mobileReachoutHeight, setMobileReachoutHeight] = useState(0);

    const showMoreFiltersDispatchFunction = () => dispatch(showMoreFilterAction(true));

    const { navOffset, absoluteSidebar, setAbsoluteSidebar, enableSidebarTransition } = useFilterSidebar(
        showMoreFilters,
        // The show more filters query is not used in modelFilter anymore due to the filter not having to close anymore. default to true
        // Be carefull editing this as it makes the sidebar absolute. Breaking some layout
        true,
        // Pass component mounting state instead of result fetcher init state as results are fetched server side in model-filter so it is always true, causing a bug(OR-5638).
        componentInitialized,
        showMoreFiltersDispatchFunction,
    );

    useEffect(() => {
        // Set component initialized on mount
        setComponentInitialized(true);

        // check if the injected reachout component is available
        if (getMobileReachoutModalSize()) {
            setMobileReachoutHeight(getMobileReachoutModalSize());
        }
    }, []);

    const sidebarChildren = useMemo(
        () => (
            <>
                {hasMobileSidebar && <ActiveFilterSliderContainer />}
                <FilterList />
            </>
        ),
        [hasMobileSidebar],
    );

    return (
        <>
            {showMoreFilters && (
                <Styles.MobileHeader>
                    <FilterPopupHeader onClose={() => dispatch(showMoreFilterAction(false))} />
                </Styles.MobileHeader>
            )}
            <Styles.Wrapper
                show={showMoreFilters}
                onTransitionEnd={(event) => {
                    // Only set the sidebar to relative if the filters are shown.
                    // If they are not shown they should be absolute as that keeps the sidebar out of view.
                    if (event.propertyName === "width" && showMoreFilters) setAbsoluteSidebar(false);
                }}
                navOffset={navOffset}
                disableTransition={!enableSidebarTransition}
                reachOutMobile={mobileReachoutHeight}
            >
                <Styles.SidebarContainer
                    show={showMoreFilters}
                    absolute={absoluteSidebar}
                    top={navOffset + FILTER_BAR_HEIGHT}
                    isFixed={isFixed}
                >
                    {absoluteSidebar ? (
                        // TODO Logic here causes a flicker to happen after the sidebar slides in. Can be adjusted by having a "disable" prop for SidebarWrapper.
                        // See OR-3238
                        <div>{sidebarChildren}</div>
                    ) : (
                        <SidebarWrapper paddingBottom={2} paddingLeft={3}>
                            {sidebarChildren}
                        </SidebarWrapper>
                    )}
                </Styles.SidebarContainer>
            </Styles.Wrapper>
        </>
    );
};

export default FilterSidebar;
