import React from "react";
import * as Styles from "./styles/BannerStyles";
import { ColorType } from "../../../../styles/v2/toyota/theme/toyotaTheme";

type BannerPropType = {
    show: boolean;
    bgColor: ColorType;
    children?: React.ReactNode;
};

const Banner: React.FC<BannerPropType> = (props) => {
    const { show, bgColor, children } = props;
    if (!show) return null;
    return <Styles.Wrapper backgroundColor={bgColor}>{children}</Styles.Wrapper>;
};

export default Banner;
