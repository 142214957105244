import React from "react";
import * as Styles from "../configuration-header/styles/FilterPopupStyle";
import FilterArrowButton from "../../common/FilterArrowButton";
import Icon from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import * as Button from "../../../../../../../common-deprecated/styles/v2/toyota/components/Button";
import * as Text from "../../../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import useFilterLabels from "../../../../hooks/useFilterLabels";
import { useModelFilterSelector } from "../../../../redux/store";
import { ModelFilterLabelType, useModelFilterLabel } from "../../../../utils/labels";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";

type FilterPopupHeader = {
    onClose: () => void;
    filterId?: ModelFilterId;
};
const FilterPopupHeader = (props: FilterPopupHeader): JSX.Element => {
    const { onClose, filterId } = props;

    let removeFilter;
    let filterCount = 0;
    if (filterId) {
        // While this is conceptually against hooks, we 100% know that this component will either always have a filterId, or never.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        ({ removeFilter, filterCount } = useFilterLabels(filterId));
    }

    const modelsCount = useModelFilterSelector((state) => state.modelResults.results.length);
    let seeResultsLabelKey: ModelFilterLabelType = "seeResults";
    if (modelsCount === 1) seeResultsLabelKey = "seeResult";
    if (modelsCount === 0) seeResultsLabelKey = "noResults";
    const seeResultsLabel = useModelFilterLabel(seeResultsLabelKey, { value: modelsCount });
    const backLabel = useModelFilterLabel("back");
    const clearFilterLabel = useModelFilterLabel(filterCount === 1 ? "clearFilter" : "clearFilters");

    // Split the clear filter label so we can apply styling.
    const clearFilterLabelSplit = clearFilterLabel.split("{value}");

    return (
        <Styles.Header>
            {filterCount === 0 && <FilterArrowButton text={backLabel} onClick={onClose} />}
            {filterId && removeFilter && filterCount > 0 && (
                <Styles.ClearButton type="button" onClick={removeFilter}>
                    <Icon variant="close" />
                    <Styles.ClearText>
                        {clearFilterLabelSplit[0]}
                        <Styles.ResultCount>{filterCount}</Styles.ResultCount>
                        {clearFilterLabelSplit[1]}
                    </Styles.ClearText>
                </Styles.ClearButton>
            )}
            <Button.Primary disabled={modelsCount === 0} onClick={onClose}>
                <Text.Button>{seeResultsLabel}</Text.Button>
            </Button.Primary>
        </Styles.Header>
    );
};

export default FilterPopupHeader;
