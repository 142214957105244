import styled from "styled-components";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as Flex from "../../../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import * as ButtonSlider from "../../../../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/styles/ButtonSliderStyles";

export const Wrapper = styled(Flex.Row)`
    margin: 0;

    /* Override styles ButtonSlider. ButtonSlider is still shared with the tridion components */
    ${ButtonSlider.Value} {
        font-family: ${theme.fonts.base};
        font-size: 1.3rem;
        line-height: 2rem;
        color: ${theme.colors.primaryBlack};
    }
`;

export const Result = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.space[4]}px ${theme.space[3]}px;
    background-color: ${theme.colors.grey1};
    border-radius: 4px;
`;

export const Label = styled.div.attrs(() => ({
    className: "t-zeta-text",
}))`
    color: ${theme.colors.grey7};
`;

export const Value = styled.div.attrs(() => ({
    className: "t-gamma-text",
}))`
    font-size: 1.6rem !important; /* Override toyota style */
    text-align: center;
    white-space: nowrap;
`;
