import React from "react";
import * as Preloader from "../../../../../../common-deprecated/styles/v2/toyota/custom/Preloader";
import * as Styles from "./styles/ModelResultStyles";
import { useModelFilterSelector } from "../../../redux/store";
import ModelResult from "./ModelResult";
import NoResults from "./NoResults";
import ComingSoonBlock from "./ComingSoonBlock";
import SrOnly from "../../../../../../common-deprecated/styles/v2/toyota/utilities/ScreenreaderOnly";
import FilterResultCount from "../active-filters/top-bar/FilterResultCount";
import { showComingSoon } from "../../../utils/helpers";
import { BusinessLeasingBanner } from "./BusinessLeasingBanner";

const ModelResultsContainer = (): JSX.Element => {
    const { initialized, results } = useModelFilterSelector((state) => state.modelResults);
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);

    const comingSoonBlockConfig = useModelFilterSelector(
        (state) => state.modelFilterSettings.comingSoonBlockConfigurations,
    );

    const resultComponent = results.map((model) => (
        <ModelResult
            // eslint-disable-next-line react/no-array-index-key
            key={model.id}
            model={model}
            data-testid="modelresult"
        />
    ));

    if (comingSoonBlockConfig && showComingSoon(commonSettings, results)) {
        comingSoonBlockConfig.forEach((config, index) => {
            resultComponent.splice(
                config.position - 1,
                0,
                <ComingSoonBlock
                    comingSoonBlockConfig={config}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${config.title}${index}`}
                />,
            );
        });
    }

    return (
        <Styles.Results>
            {initialized ? (
                <>
                    <BusinessLeasingBanner />
                    {/* Gives an additional sr-only text to amount of results after tabbing away from the sidebar */}
                    <SrOnly>
                        <FilterResultCount show={false} sruAccessible={0} />
                    </SrOnly>
                    <Styles.Grid>{resultComponent}</Styles.Grid>
                    {!results.length && <NoResults />}
                </>
            ) : (
                <Preloader.Wrapper width={1} position="relative">
                    <Preloader.default data-testid="preloader" />
                </Preloader.Wrapper>
            )}
        </Styles.Results>
    );
};

export default ModelResultsContainer;
