import { CommonSettingsType } from "../../../settings/fetchCommonSettings";
import { searchModelMapValue } from "../../../settings/utils/commonSettingUtils";
import { propagateCommonQueryParamsAsString } from "../../../utils";

export enum CarConfigStep {
    Highlights = "highlights",
    Configure = "configure",
    Specs = "specs",
}

export const getCarConfigStepPath = (carConfigStep: CarConfigStep, modelId: string, carId: string): string => {
    return `${carConfigStep}/${modelId}/${carId}`;
};

export const getCarConfigUrl = (
    commonSettings: CommonSettingsType,
    modelId: string,
    modelCode: string,
    carId: string,
    carConfigStep: CarConfigStep,
    colour?: { colourId: string; colourCode: string },
    upholstery?: { upholsteryId: string; upholsteryCode: string },
    wheelId?: string,
): string => {
    const { country, language } = commonSettings;

    const queryParam = propagateCommonQueryParamsAsString(commonSettings, {
        path: getCarConfigStepPath(carConfigStep, modelId, carId),
        c: colour?.colourId,
        u: upholstery?.upholsteryId,
        w: wheelId,
    });

    if (commonSettings.isStandalone) {
        return `/${country}/${language}/car-config?${queryParam}`;
    }

    const baseUrl = searchModelMapValue<CommonSettingsType>(commonSettings, modelId)?.links?.buildPage;

    if (!baseUrl) {
        return "";
    }

    // If only colour is present, AEM selectors can be used in stead.
    if (colour && !upholstery && !wheelId) {
        // colourCode needs to be lowercase in the AEM selectors otherwise this will not work.
        return `${baseUrl}.${modelCode as string}.${colour.colourCode.toLowerCase() as string}.${carId as string}`;
    }

    const baseUrlParts = baseUrl.split("/");
    baseUrlParts[baseUrlParts.length - 2] = `${baseUrlParts[baseUrlParts.length - 2]}`;

    return `${baseUrlParts.join("/")}?${queryParam}`;
};

export const getStandaloneGradeSelectorUrl = (modelId: string, commonSettings: CommonSettingsType): string => {
    const queryParam = propagateCommonQueryParamsAsString(commonSettings, {
        modelId,
    });
    return `/${commonSettings.country}/${commonSettings.language}/grade-selector?${queryParam}`;
};
