import styled, { css } from "styled-components";
import { getBreakpoint } from "../../../../themes/common";
import { parseHexToRGB } from "../../../../utils";
import { theme } from "../theme/toyotaTheme";

const bgColorDark = parseHexToRGB(theme.colors.primaryBlack).rgb;
const bgColorLight = parseHexToRGB(theme.colors.primaryWhite).rgb;

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 360px;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
    @media ${getBreakpoint("up", "md")} {
        min-height: auto;
    }
`;

export const Content = styled.div`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 56px 0;
    z-index: 100;

    @media ${getBreakpoint("up", "lg")} {
        padding: 120px 0;
    }
`;

export const MediaWrapper = styled.div<{ showBackground: boolean; lightBackground?: boolean }>`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    overflow: hidden;
    z-index: 10;

    ${({ showBackground, lightBackground }) =>
        showBackground &&
        css`
            background-color: rgba(${lightBackground ? bgColorLight : bgColorDark}, 0.5);
        `};

    img,
    video {
        position: absolute;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
`;

export const TopContent = styled.div`
    width: 100%;
    text-align: center;
`;

export const BottomContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: ${theme.space[4]}px;
`;

export const Title = styled.h2<{ lightText?: boolean }>`
    margin: 0;
    font-family: ${theme.fonts.regular};
    font-size: 2.8rem;
    line-height: 3.2rem;
    text-align: center;
    text-shadow: 2px 2px 2px ${({ lightText }) => (lightText ? theme.colors.grey2 : theme.colors.grey8)};
    text-transform: none;
    color: ${({ lightText }) => (lightText ? theme.colors.primaryWhite : theme.colors.primaryBlack)};
`;

export const SubTitle = styled.div<{ lightText?: boolean }>`
    margin: 0;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    text-shadow: 2px 2px 2px ${({ lightText }) => (lightText ? theme.colors.grey2 : theme.colors.grey8)};
    color: ${({ lightText }) => (lightText ? theme.colors.primaryWhite : theme.colors.primaryBlack)};
`;
