import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import ActiveFilterSlider from "./ActiveFilterSlider";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";
import * as Styles from "../configuration-header/styles/ConfigurationHeaderContainerStyle";
import { ModelFilterLabel } from "../../../../utils/labels";
import { IconWrapper } from "../../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { clearAllFilters } from "../../../../utils/filters";

/**
 * Container for the ActiveFilterSlider.
 * This container is required because the ActiveFilterSlider works with a two pass rendering system so it cannot hide itself.
 */
const ActiveFilterSliderContainer = (): JSX.Element | null => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const modelFilters = useModelFilterSelector((state) => state.modelFilters);

    const sliderComponents = Object.values(ModelFilterId)
        .filter((filterId) => modelFilters[filterId].active)
        .sort((a, b) => modelFilters[a].index - modelFilters[b].index);

    const filtersKey = [...sliderComponents].sort().join();

    // Prevent unnecessary rerenders by using filtersKey, which is also used in ActiveFilterSlider to prevent rerenders.
    return useMemo(
        () =>
            sliderComponents.length === 0 ? null : (
                <>
                    <ActiveFilterSlider activeFilters={sliderComponents} activeFiltersKey={filtersKey} />
                    <Styles.MobileFooter>
                        <Styles.FilterCount>
                            <ModelFilterLabel label="activeFilterCount" params={{ value: sliderComponents.length }} />
                        </Styles.FilterCount>
                        <Styles.RemoveButton onClick={() => clearAllFilters(dispatch, modelFilters)}>
                            <ModelFilterLabel label="clearAllFilters" />
                            <IconWrapper>
                                <Icon variant="close" />
                            </IconWrapper>
                        </Styles.RemoveButton>
                    </Styles.MobileFooter>
                </>
            ),
        [filtersKey, sliderComponents],
    );
};

export default ActiveFilterSliderContainer;
