import styled from "styled-components";
import * as ButtonSliderStyles from "../../../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/styles/ButtonSliderStyles";
import * as FilterDropdownStyles from "./FilterDropdownStyles";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div`
    text-align: left;
    overflow-x: hidden;
    ${FilterDropdownStyles.Wrapper} {
        background: none;
    }

    ${ButtonSliderStyles.Track} {
        background: ${theme.colors.primaryBlack};
    }
`;
