import styled, { css } from "styled-components";
import { width, WidthProps, zIndex, ZIndexProps, top, PositionProps, space, SpaceProps } from "styled-system";

export const Wrapper = styled.div<
    PositionProps & WidthProps & ZIndexProps & SpaceProps & { disableSticky?: boolean; isMobile: boolean }
>`
    top: 0;
    ${({ disableSticky }) => !disableSticky && "position: sticky;"}
    ${width};
    ${zIndex};
    ${space};
    transition: top 0.5s ease-out;

    ${({ isMobile }) =>
        !isMobile &&
        css`
            ${top};
        `};
`;

export default Wrapper;
