import styled from "styled-components";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as SortFilter from "../../top-bar/styles/SortFiltersStyles";
import * as FilterPopupStyles from "./FilterPopupStyle";
import { IconWrapper } from "../../../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { getBreakpoint, getDirection } from "../../../../../../../../common-deprecated/themes/common";

export const Viewport = styled.div`
    display: flex;
    flex: 1 1 auto;
    max-width: 100%;
    padding: 0;
    text-align: left;
    overflow-y: hidden;

    &::after {
        display: block;
        position: absolute;
        top: 0;
        ${getDirection("right")}: -${theme.spacer / 2}px;
    }

    @media ${getBreakpoint("up", "md")} {
        overflow-y: visible;
    }

    @media ${getBreakpoint("up", "lg")} {
        max-width: none;
        text-align: center;
        overflow-y: visible;

        &::after {
            display: none;
        }
    }
`;

export const Wrapper = styled.div`
    position: relative;
    justify-content: center;
    width: 100%;
    padding: ${theme.space[3]}px 0;

    ${SortFilter.Wrapper} {
        padding-bottom: ${theme.space[1]}px;
        padding-${getDirection("right")}: ${theme.space[1]}px;

        @media ${getBreakpoint("down", "lg")} {
            display: none;
        }
    }

    @media ${getBreakpoint("up", "lg")} {
        flex-wrap: wrap;
        margin: 0 auto;
    }

    ${FilterPopupStyles.Header} {
        width: 100%;
        margin: 0;
    }
`;

export const Pills = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 ${theme.space[2]}px;
`;

export const MobileFooter = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${theme.space[2]}px ${theme.space[3]}px 0;

    @media ${getBreakpoint("up", "lg")} {
        display: none;
    }
`;

export const DesktopFooter = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;

    @media ${getBreakpoint("down", "lg")} {
        display: none;
    }
`;

export const FilterCount = styled.div``;

export const RemoveButton = styled.button`
    ${IconWrapper} {
        margin-${getDirection("left")}: ${theme.space[1]}px;
    }
`;
