import React from "react";
import * as Button from "../../../../../../common-deprecated/styles/v2/toyota/components/Button";
import * as Text from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import { IconWrapper } from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { isRTL } from "../../../../../../common-deprecated/settings/utils/commonSettingUtils";
import { useModelFilterSelector } from "../../../redux/store";

type FilterBackButtonType = {
    onClick: () => void;
    text: string;
};

// TO DO: add RTL support
const FilterArrowButton = ({ onClick, text }: FilterBackButtonType): JSX.Element => {
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);
    return (
        <Button.Link type="button" hasIcon onClick={onClick}>
            <IconWrapper aria-hidden="true" iconColor="primaryBlack">
                <Icon variant={isRTL(commonSettings) ? "arrowRight" : "arrowLeft"} />
            </IconWrapper>
            <Text.Button>{text}</Text.Button>
        </Button.Link>
    );
};

export default FilterArrowButton;
