import React from "react";
import { useDispatch } from "react-redux";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import { useModelFilterLabel } from "../../../../utils/labels";
import * as Styles from "./styles/StaticFilterBarStyles";
import { IconWrapper } from "../../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import SortFilters from "./SortFilters";
import { showMoreFilters } from "../../../../redux/actions/ModelFiltersActions";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";
import { useAemNavOffset } from "../../../../../../../common-deprecated/hooks";

type StaticFilterBarType = { isFixed: boolean };

const StaticFilterBar = ({ isFixed }: StaticFilterBarType): JSX.Element => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const showFilters = useModelFilterSelector((state) => state.modelFilters.showMoreFilters);
    const modelFilters = useModelFilterSelector((state) => state.modelFilters);

    // Get filters to get number of active filters
    const filters = Object.values(ModelFilterId).filter((filterId) => modelFilters[filterId].active);

    const allFiltersLabel = useModelFilterLabel("allFilters");

    const topOffset = useAemNavOffset();

    return (
        <Styles.Wrapper isFixed={isFixed}>
            <Styles.Inner isFixed={isFixed}>
                <Styles.Item>
                    <Styles.Button onClick={() => dispatch(showMoreFilters(!showFilters))}>
                        <Styles.ButtonLabel>{allFiltersLabel}</Styles.ButtonLabel>
                        <Styles.ButtonIcon>
                            {filters.length > 0 && isFixed && (
                                <Styles.ButtonIconLabel>{filters.length}</Styles.ButtonIconLabel>
                            )}
                            <IconWrapper>
                                <Icon variant="controls" />
                            </IconWrapper>
                        </Styles.ButtonIcon>
                    </Styles.Button>
                </Styles.Item>
                <Styles.Item>
                    <SortFilters topOffset={topOffset} isFixed={isFixed} />
                </Styles.Item>
            </Styles.Inner>
        </Styles.Wrapper>
    );
};

export default StaticFilterBar;
