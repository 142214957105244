import React from "react";
import ModelResultMonthlyPrice from "./ModelResultMonthlyPrice";
import ModelResultCashPrice from "./ModelResultCashPrice";
import { ModelResultType } from "../../../../../../../shared-logic/features/filters/utils/constants/resultConstants";
import { getCashPriceDisclaimerLabel } from "../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { isIsrael } from "../../../../../../../common-deprecated/settings/utils/commonSettingUtils";
import ModelResultCashPriceIsrael from "./ModelResultCashPriceIsrael";
import { useModelFilterSelector } from "../../../../redux/store";

type ModelResultPriceType = {
    model: ModelResultType;
};

const ModelResultPrice = (props: ModelResultPriceType): JSX.Element | null => {
    const { model } = props;
    const { cars, cheapestCar = cars[0], cheapestCarMonthly = cars[0] } = model;

    const commonSettings = useModelFilterSelector((state) => state.commonSettings);

    if (!cheapestCar.price || cheapestCar.price.cash === 0) return null;

    const cashPriceDisclaimer = cheapestCar.model.labels;

    // Cash and monthly are separate components for easier readability and maintenance.
    return (
        <>
            {isIsrael(commonSettings.culture.name) ? (
                <ModelResultCashPriceIsrael
                    price={cheapestCar?.price}
                    modelCode={cheapestCar.model.code}
                    cashPriceDisclaimer={cashPriceDisclaimer && getCashPriceDisclaimerLabel(cashPriceDisclaimer)}
                />
            ) : (
                <ModelResultCashPrice price={cheapestCar?.price} modelCode={cheapestCar.model.code} />
            )}
            {!!cheapestCarMonthly?.price.monthly && (
                <ModelResultMonthlyPrice
                    financeInfo={cheapestCarMonthly.financeInfo}
                    price={cheapestCarMonthly.price}
                    modelCode={cheapestCarMonthly.model.code}
                />
            )}
        </>
    );
};

export default ModelResultPrice;
