// @ts-ignore
import uniqueSel from "unique-selector";
import { Store } from "redux";
import { sendJsonToSalesman } from "./utils";
import {
    setExteriorSpinFrame,
    startExteriorSpinPreload,
    setHListPage,
    set3DInteriorPosition,
    set3DExteriorPosition,
} from "./redux/actions/UtilActions";
import {
    SALESMAN_HLIST_PAGE,
    SALESMAN_EXTSPIN_LOAD,
    SALESMAN_EXTSPIN_ANGLE,
    SALESMAN_CAREXPLORER_FILTERVALUE,
    SALESMAN_QUOTE_CONFIG,
    SALESMAN_SCROLL_OVERLAY,
    SALESMAN_CLICK_SELECTOR,
    SALESMAN_3D_INTERIOR_POSITION,
    SALESMAN_3D_EXTERIOR_POSITION,
} from "./constants";
import { CommonSettingsType } from "./settings/fetchCommonSettings";
import { isMaster, isSlave } from "./settings/utils/commonSettingUtils";

// Set this variable to true if you want to debug and test salesman logic locally
// You can access both master and slave by adding &salesmanmaster=true or &salesmanslave=true to the url
// The sendJsonToSalesman method also contains a console log for testing.
const debugSalesman = false;
if (debugSalesman) {
    (window as any).debugMessage = (action: string, data: any) =>
        (window as any).postMessage(`json=${JSON.stringify({ action, data })}`);
}

/**
 * Executed when a component has Salesman integration.
 */
export default (settings: CommonSettingsType, store: Store): void => {
    // Send native events
    if (isMaster(settings)) {
        document.addEventListener(
            "click",
            (event: MouseEvent) => {
                const element = event.target as Element;

                // Block checkbox input double click events
                if (
                    !element ||
                    (element.nodeName.toLowerCase() === "input" && element.getAttribute("type") === "checkbox")
                ) {
                    return;
                }

                const uid = uniqueSel(event.target);
                sendJsonToSalesman({ action: SALESMAN_CLICK_SELECTOR, data: uid });
                if (debugSalesman) console.log("click", { data: uid });
            },
            // This extra prop causes this eventlistener to happen before everything else (for example, the element's onClick and possible react rerenders)
            { capture: true },
        );
    }

    // Receive all types of events
    if (isSlave(settings)) {
        window.addEventListener("message", (event) => {
            const { origin, data } = event;
            // Return if not coming from billboard chrome app
            if (origin && origin.indexOf("chrome-extension://") !== 0 && !debugSalesman) return;
            if (data && typeof data === "string") {
                const action = data.split("=")[0] || "";
                // Actual data can also contain multiple equal signs, so just take anything past the first "=" as payload.
                const payload = data.substring(data.indexOf("=") + 1) || "";

                if (debugSalesman) console.log("message", { action, payload });

                // Handle native scroll events
                if (action.toLowerCase() === "scroll") {
                    const scrollHeight = document.body ? document.body.scrollHeight : 0;
                    window.scrollTo(0, (scrollHeight - window.innerHeight) * parseFloat(payload));
                }

                // handle custom events
                else if (action.toLowerCase() === "json") {
                    // parse json
                    let json = null;
                    try {
                        json = JSON.parse(payload);
                        if (!json.action) throw new Error("JSON object does not have an action defined.");
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.warn(e);
                        return;
                    }

                    // dispatch custom events
                    switch (json.action) {
                        // sync the current page of the Build V1 personalization item and wheel conflicts slider
                        case SALESMAN_HLIST_PAGE: {
                            store.dispatch(setHListPage(json.data.className, json.data.page));
                            break;
                        }

                        // starts preloading the exterior spin images
                        case SALESMAN_EXTSPIN_LOAD: {
                            store.dispatch(startExteriorSpinPreload(json.data));
                            break;
                        }

                        // sync the current angle / frame visible in the exterior spin
                        case SALESMAN_EXTSPIN_ANGLE: {
                            store.dispatch(setExteriorSpinFrame(json.data.key, json.data.frame));
                            break;
                        }

                        // sync the current azimut and polar angles in the interior 3d spin
                        case SALESMAN_3D_INTERIOR_POSITION: {
                            store.dispatch(set3DInteriorPosition(json.data.x, json.data.y, json.data.z));
                            break;
                        }

                        // sync the current azimut and polar angles in the exterior 3D spin
                        case SALESMAN_3D_EXTERIOR_POSITION: {
                            store.dispatch(set3DExteriorPosition(json.data.x, json.data.y, json.data.z));
                            break;
                        }

                        // sync filter values in car-explorer
                        case SALESMAN_CAREXPLORER_FILTERVALUE: {
                            store.dispatch(json.data);
                            break;
                        }

                        // request a new finance quote config
                        case SALESMAN_QUOTE_CONFIG: {
                            store.dispatch(json.data);
                            break;
                        }

                        // a custom event is needed to sync scrolling inside a modal or the compare v2
                        case SALESMAN_SCROLL_OVERLAY: {
                            const modals =
                                document.querySelector("section.or-modal") ||
                                document.querySelector(".or-modal-body:last-child"); // support old and new modals
                            if (modals) modals.scrollTop = json.data || 0;
                            break;
                        }

                        // This needs to be last (and switch needs breaks) because pressing a button that triggers a custom action would otherwise trigger twice
                        case SALESMAN_CLICK_SELECTOR: {
                            // general click handler
                            const match = document.querySelector(json.data);
                            // a simple .click() only works on html objects, not svg, so we need to add pointer-events:none to all svgs so their html parents are selected instead
                            if (match && match.click) match.click();
                            // legacy fixes for car-explorer, everything should get it's own case constant now
                            else {
                                // eslint-disable-next-line no-console
                                console.warn("No match found for: ", json.data);
                            }
                            break;
                        }
                    }
                }
            }
        });
    }
};
