import { ecoTagToLabel } from "../../../../common-deprecated/utils/ecoTagUtils";
import { useCommonSelector } from "../../../../common-deprecated/redux/commonStore";
import { EcoTagType } from "../../../../common-deprecated/types/CommonTypes";
import { getCommonOptionalLabel } from "../../../../common-deprecated/utils/commonLabels";

const usePowertrainLabels = (ecoTag: EcoTagType | false): string | null => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);
    const label = ecoTagToLabel(ecoTag !== false ? ecoTag : undefined);
    return label ? getCommonOptionalLabel({ commonSettings }, label) : null;
};

export default usePowertrainLabels;
