import React from "react";
import * as Styles from "../../../../../../common-deprecated/styles/v2/toyota/custom/ComingSoonBlock";
import * as Button from "../../../../../../common-deprecated/styles/v2/toyota/components/Button";
import * as Text from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import { ImageFluid } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Image";
import { ModelFilterComingSoonBlockConfigType } from "../../../server/AEMModelFilterSettingsTypes";
import { getAemImageUrl } from "../../../../../../common-deprecated/utils";

const ComingSoonBlock = (props: { comingSoonBlockConfig: ModelFilterComingSoonBlockConfigType }): JSX.Element => {
    const { title, subtitle, linkLabel, linkUrl, bannerType, bannerUrl, bannerLight } = props.comingSoonBlockConfig;
    const isVideo = bannerType === "video";

    return (
        <Styles.Wrapper onClick={() => linkUrl && window.location.assign(linkUrl)}>
            <Styles.MediaWrapper
                showBackground={isVideo || !bannerUrl}
                lightBackground={bannerLight}
                data-testid="comingsoonblock"
            >
                {bannerUrl &&
                    (isVideo ? (
                        <video autoPlay muted loop playsInline>
                            <source src={bannerUrl} type="video/mp4" />
                        </video>
                    ) : (
                        // dimensions were chosen arbitrarily based on what a 4k ultrawide and some margin
                        <ImageFluid
                            src={getAemImageUrl(bannerUrl, { fit: "wrap", wid: 1200, hei: 700, fmt: "png-alpha" })}
                            alt={title}
                        />
                    ))}
            </Styles.MediaWrapper>
            <Styles.Content>
                <Styles.TopContent>
                    <Styles.Title lightText={!bannerLight}>{title}</Styles.Title>
                    {subtitle && <Styles.SubTitle lightText={!bannerLight}>{subtitle}</Styles.SubTitle>}
                </Styles.TopContent>
                <Styles.BottomContent>
                    <Button.Primary as="a" href={linkUrl} mb={2} light={!bannerLight}>
                        <Text.Button>{linkLabel}</Text.Button>
                    </Button.Primary>
                </Styles.BottomContent>
            </Styles.Content>
        </Styles.Wrapper>
    );
};

export default ComingSoonBlock;
