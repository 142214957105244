import React from "react";
import { useModelFilterSelector } from "../../../../redux/store";
import * as Type from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import * as Styles from "./styles/FilterResultCountStyles";
import { ModelFilterLabel, useModelFilterLabel } from "../../../../utils/labels";
import SrOnly from "../../../../../../../common-deprecated/styles/v2/toyota/utilities/ScreenreaderOnly";

type FilterResultCountType = { show: boolean; sruAccessible: number; hasHeading?: boolean };
const FilterResultCount = (props: FilterResultCountType): JSX.Element => {
    const { show, sruAccessible, hasHeading } = props;
    const resultCount = useModelFilterSelector((state) => state.modelResults.totalResultCount);
    const modelsCount = useModelFilterSelector((state) => state.modelResults.results.length);
    const initialized = useModelFilterSelector((state) => state.modelResults.initialized);

    const modelsCountLabel = useModelFilterLabel("modelsCount");
    const resultCountLabel = useModelFilterLabel("resultCount");

    // Split the modelsCountLabel and resultCountLabel so we can apply styling to the count number.
    const modelsCountLabelSplit = modelsCountLabel.split("{value}");
    const resultCountLabelSplit = resultCountLabel.split("{value}");

    return (
        <Styles.Wrapper role="status" show={show && initialized} tabIndex={sruAccessible}>
            <SrOnly>
                <ModelFilterLabel label="ariaFilterResults" />:
            </SrOnly>
            <div role={hasHeading ? "heading" : undefined} aria-level={hasHeading ? 4 : undefined}>
                <Type.Text headingType="milli">{modelsCountLabelSplit[0]}</Type.Text>
                <Type.Text headingType="milli" styleType="headline">
                    {modelsCount}
                </Type.Text>
                <Type.Text headingType="milli">{modelsCountLabelSplit[1]}</Type.Text>
            </div>
            <div>
                <Type.Text headingType="milli">{resultCountLabelSplit[0]}</Type.Text>
                <Type.Text headingType="milli" styleType="headline">
                    {resultCount}
                </Type.Text>
                <Type.Text headingType="milli">{resultCountLabelSplit[1]}</Type.Text>
            </div>
        </Styles.Wrapper>
    );
};

export default FilterResultCount;
