import React, { ReactNode } from "react";
import { ResponsiveValue, SpaceProps, TLengthStyledSystem } from "styled-system";
import type { Property } from "csstype";
import { useAemNavOffset } from "../hooks";
import * as Styles from "./styles/SidebarWrapperStyles";
import { useBreakpoint } from "../themes/common";

type SidebarWrapperPropsType = {
    children: ReactNode;
    ignoreNavOffset?: boolean;
    top?: ResponsiveValue<Property.Top<TLengthStyledSystem>>;
    zIndex?: number;
    // Used on retailerscreens because it is not possible to see all collapsed filters on the big screen at once
    disableSticky?: boolean;
};

const SidebarWrapper = (props: SidebarWrapperPropsType & SpaceProps): JSX.Element => {
    const { children, top = 0, ignoreNavOffset, disableSticky } = props;
    const navOffset = useAemNavOffset();
    const combinedTop = typeof top === "number" ? (ignoreNavOffset ? 0 : navOffset) + (top || 0) : top;
    const isMobile = useBreakpoint("down", "xl");
    return (
        <Styles.Wrapper
            id="or-sidebar-wrapper"
            {...props}
            top={combinedTop}
            disableSticky={disableSticky}
            isMobile={isMobile}
        >
            {children}
        </Styles.Wrapper>
    );
};

export default SidebarWrapper;
