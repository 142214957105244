import styled from "styled-components";
import { space, borders, SpaceProps, BordersProps } from "styled-system";
import { getDirection } from "../../../../../../../themes/common";

export const Wrapper = styled.div<SpaceProps & BordersProps>`
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    line-height: 2rem;
    border-color: ${({ theme }) => theme.color.grey3};
    user-select: none;
    ${space};
    ${borders};
`;

export const Title = styled.div``;

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.textColor.muted};
`;

export const Min = styled.div`
    padding-${getDirection("right")}: 6px;

    ${Title},
    ${Subtitle} {
        text-align: left;
    }
`;

export const Max = styled.div`
    padding-${getDirection("left")}: 6px;

    ${Title},
    ${Subtitle} {
        text-align: right;
    }
`;
