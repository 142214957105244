import { useState } from "react";
import { useDispatch } from "react-redux";
import { ModelFilterDispatchType, useModelFilterSelector } from "../redux/store";
import { useCloseOnOutsideClick } from "../../../../common-deprecated/hooks";
import { trackEvent } from "../../../../common-deprecated/utils/tracking";
import { COMPONENT_NAME_MODEL_FILTER } from "../../../../common-deprecated/AnalyticsConstants";
import { setModelResultsOrder } from "../redux/actions/ModelResultsActions";
import { sortOrderLabelMap, ModelFilterLabelType } from "../utils/labels";
import { COMPONENT_MODEL_FILTER } from "../../../../shared-logic/server/components";
import { ModelFilterSortOrder } from "../utils/constants/filterConstants";
import { showPrice } from "../../../../common-deprecated/settings/utils/financeSettingUtilsCommon";
import {
    getAemPriceConfig,
    showAemMonthlyRates,
} from "../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";

type DataType = {
    showPopup: boolean;
    togglePopup: () => void;
    items: { id: ModelFilterSortOrder; labelKey: ModelFilterLabelType; onClick: () => void }[];
};

const useSortFilters = (popupRef: React.RefObject<HTMLElement>): DataType => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const disableMonthlySort = useModelFilterSelector((state) => state.modelFilterSettings.newCarDisableMonthlySort);
    const hasResultsWithFinance = useModelFilterSelector((state) =>
        state.modelResults.results.some((result) => !!result.cheapestCarMonthly?.price.monthly),
    );
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);

    const shouldShowPrice =
        showPrice(commonSettings) && getAemPriceConfig(commonSettings, AemFmComponent.ModelFilter)?.priceEnabled;
    const shouldShowMonthly = showAemMonthlyRates(commonSettings, AemFmComponent.ModelFilter, "new");

    const [showPopup, setShowPopup] = useState(false);
    useCloseOnOutsideClick(popupRef, () => setShowPopup(false));

    const togglePopup = (): void => {
        if (!showPopup) {
            trackEvent({
                component: COMPONENT_NAME_MODEL_FILTER,
                group: "new-cars",
                action: "open-sorting-menu",
                label: "",
            });
        }
        setShowPopup(!showPopup);
    };

    const items = Object.values(ModelFilterSortOrder)
        .filter((sortOrderKey) => {
            // Filter out specific sort orders.
            switch (sortOrderKey) {
                case ModelFilterSortOrder.CashAsc:
                case ModelFilterSortOrder.CashDesc:
                    return shouldShowPrice;

                case ModelFilterSortOrder.MonthlyAsc:
                case ModelFilterSortOrder.MonthlyDesc:
                    return shouldShowPrice && shouldShowMonthly && !disableMonthlySort && hasResultsWithFinance;

                default:
                    return true;
            }
        })
        .map((sortOrderKey: ModelFilterSortOrder) => ({
            id: sortOrderKey,
            labelKey: sortOrderLabelMap[sortOrderKey],
            onClick: () => {
                trackEvent({
                    component: COMPONENT_MODEL_FILTER,
                    group: "new-cars",
                    action: "sort",
                    label: sortOrderKey,
                });
                setShowPopup(false);
                dispatch(setModelResultsOrder(sortOrderKey));
            },
        }));

    return { showPopup, togglePopup, items };
};

export default useSortFilters;
