import React, { RefObject, useRef } from "react";
import { useDispatch } from "react-redux";
import FocusTrap from "focus-trap-react";
import { useCloseOnOutsideClick } from "../../../../../../../common-deprecated/hooks";
import FilterWrapper from "../../filters/FilterWrapper";
import * as Style from "./styles/FilterPopupStyle";
import * as Button from "../../../../../../../common-deprecated/styles/v2/toyota/components/Button";
import * as Text from "../../../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import { useModelFilterLabel } from "../../../../utils/labels";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import { showMoreFilters as showMoreFiltersAction } from "../../../../redux/actions/ModelFiltersActions";
import FilterPopupHeader from "../top-bar/FilterPopupHeader";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";
import useFilterPopup from "../../../../../../../shared-logic/features/filters/hooks/useFilterPopup";
import { PosType } from "../../../../../../../common-deprecated/types/CommonTypes";

type FilterPopupWrapperType = {
    filterId: ModelFilterId;
    containerRef: RefObject<HTMLDivElement>;
    closeFilter: () => void;
    originalPos: PosType;
};

const FilterPopup = (props: FilterPopupWrapperType): JSX.Element => {
    const { closeFilter, filterId, containerRef, originalPos } = props;
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const filterRef = useRef<HTMLDivElement>(null);
    const showAllFiltersLabel = useModelFilterLabel("showAllFilters");
    const showMoreFilters = useModelFilterSelector((state) => state.modelFilters.showMoreFilters);

    useCloseOnOutsideClick(filterRef, closeFilter);
    const position = useFilterPopup(filterRef, containerRef, originalPos);

    return (
        <FocusTrap>
            <Style.Wrapper left={position.x} top={position.y} ref={filterRef}>
                <FilterPopupHeader filterId={filterId} onClose={closeFilter} />
                <Style.Body>
                    <FilterWrapper filterId={filterId} />
                </Style.Body>
                {!showMoreFilters && (
                    <Style.Footer>
                        <Button.Secondary
                            type="button"
                            onClick={() => {
                                closeFilter();
                                dispatch(showMoreFiltersAction(true));
                            }}
                        >
                            <Text.Button>{showAllFiltersLabel}</Text.Button>
                        </Button.Secondary>
                    </Style.Footer>
                )}
            </Style.Wrapper>
        </FocusTrap>
    );
};

export default FilterPopup;
