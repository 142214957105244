import styled from "styled-components";
import * as Type from "../../../../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div<{ show: boolean }>`
    text-align: center;
    transition: opacity 0.15s;
    opacity: ${(props) => (props.show ? 1 : 0)};

    ${Type.Text} {
        color: ${theme.colors.grey4};
    }
`;
