import React, { SyntheticEvent, useRef } from "react";
import * as Styles from "../../../../../../common-deprecated/styles/v2/toyota/components/Pill";
import * as Text from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import { IconWrapper } from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { PosType } from "../../../../../../common-deprecated/types/CommonTypes";
import { getOffsetRect } from "../../../../../../shared-logic/features/filters/utils/helpers";
import { SrOnly } from "../../../../../../common-deprecated/styles/v2/toyota/utilities/ScreenreaderOnly";
import { useModelFilterLabel } from "../../../utils/labels";

type FilterPillButtonType = {
    children: React.ReactNode;
    onClick: (pos: PosType) => void;
    closeFn?: () => void;
    isSelected: boolean;
    disabled?: boolean;
};

const FilterPillButton = ({ children, closeFn, onClick, disabled, isSelected }: FilterPillButtonType): JSX.Element => {
    const pillButtonRef = useRef<HTMLButtonElement>(null);
    const isCloseFn = !!closeFn;
    const [addFilterLabel, removeFilterLabel] = useModelFilterLabel(["ariaAddFilter", "ariaRemoveFilter"]);

    return (
        <Styles.Wrapper
            type="button"
            role="button"
            hasIconRight
            onClick={() => {
                if (!disabled && pillButtonRef.current) {
                    const { left: x, bottom: y } = getOffsetRect(pillButtonRef.current);

                    onClick({ x, y });
                }
            }}
            disabled={!!disabled}
            isActive={!!closeFn}
            isSelected={isSelected}
            ref={pillButtonRef}
        >
            <SrOnly>{addFilterLabel}</SrOnly>
            <Text.Button>{children}</Text.Button>
            <IconWrapper
                role={isCloseFn ? "button" : ""}
                tabIndex={isCloseFn ? 0 : -1}
                onClick={
                    closeFn
                        ? (event: SyntheticEvent) => {
                              // Prevent click on wrapper
                              event.preventDefault();
                              event.stopPropagation();
                              closeFn();
                          }
                        : undefined
                }
                aria-hidden={!isCloseFn}
                aria-label={removeFilterLabel}
            >
                <Icon variant={isCloseFn ? "close" : "plus"} />
            </IconWrapper>
        </Styles.Wrapper>
    );
};

export default FilterPillButton;
