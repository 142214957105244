import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonSlider from "../../../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSlider";
import {
    MinMaxSliderFilterConfigType,
    SLIDER_INCREASING,
} from "../../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import FilterDescription from "../../../../../../../common-deprecated/features/filters/components/toyota/filters/FilterDescription/FilterDescription";
import { getSliderDescriptionLabel } from "../../../../../../../shared-logic/features/filters/utils/filterLabelUtils";
import { setSliderValue } from "../../../../redux/actions/ModelFiltersActions";
import { ModelFilterLabelType, useModelFilterLabel } from "../../../../utils/labels";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as Flex from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import * as Styles from "./styles/MinMaxStyles";
import { MinMaxSliderModelFilterIdType, ModelFilterId } from "../../../../utils/constants/filterConstants";
import { useCommonSelector } from "../../../../../../../common-deprecated/redux/commonStore";
import * as commonSettingUtils from "../../../../../../../common-deprecated/settings/utils/commonSettingUtils";
import ButtonSliderRTL from "../../../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSliderRTL";

/**
 * @deprecated Now using titleLabel directly from filterConfig in component-settings
 */
const filterTitleLabelMap: Partial<Record<MinMaxSliderModelFilterIdType, ModelFilterLabelType>> = {
    [ModelFilterId.Eta]: "filterLabelEta",
    [ModelFilterId.Doors]: "filterLabelDoors",
    [ModelFilterId.Seats]: "filterLabelSeats",
    [ModelFilterId.PowerOutput]: "filterLabelPowerOutput",
    [ModelFilterId.Co2]: "filterLabelco2",
    [ModelFilterId.FuelConsumption]: "filterLabelFuelConsumption",
    [ModelFilterId.Luggage]: "filterLabelLuggage",
    [ModelFilterId.Speed]: "filterLabelSpeed",
};

type MinMaxSliderFilterType = { filterId: MinMaxSliderModelFilterIdType };

const MinMaxSliderFilter = ({ filterId }: MinMaxSliderFilterType): JSX.Element => {
    const filter: MinMaxSliderFilterConfigType = useModelFilterSelector((state) => state.modelFilters[filterId]);
    const dispatch = useDispatch<ModelFilterDispatchType>();

    // Just ignoring this since filterId will never change & this is deprecated functionality anyways
    const deprecatedTitleLabel = filterTitleLabelMap[filterId]
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useModelFilterLabel(filterTitleLabelMap[filterId]!)
        : null;
    const titleLabel = filter.titleLabel || deprecatedTitleLabel;
    const isRTL = useCommonSelector((state) => commonSettingUtils.isRTL(state.commonSettings));

    const { currentMinValue, currentMaxValue, minValue, maxValue, valueLabelConfig } = filter;
    const { minValueText, minValueDesc, maxValueDesc, maxValueText, value } = valueLabelConfig;

    const getValueLabel = useCallback(
        (max: number, min: number): string => {
            const minVal = isRTL ? max : min;
            const maxVal = isRTL ? min : max;

            return value.includes("{value}")
                ? value.replace("{value}", `${minVal} - ${maxVal}`)
                : value.replace("{min}", String(minVal)).replace("{max}", String(maxVal));
        },
        [value],
    );

    const setValue = useCallback(
        (newValue: number, type: "min" | "max"): void => {
            dispatch(setSliderValue(type, newValue, filterId));
        },
        [dispatch, filterId],
    );

    const [valueLabel, setValueLabel] = useState<string>(getValueLabel(currentMaxValue, currentMinValue));

    return (
        <Styles.Wrapper>
            <Flex.Col width={1} px={0} mb={theme.space[2]}>
                <Styles.Result>
                    {titleLabel && <Styles.Label>{titleLabel}</Styles.Label>}
                    <Styles.Value>{valueLabel}</Styles.Value>
                </Styles.Result>
            </Flex.Col>

            <Flex.Col px={0} width={1}>
                {isRTL ? (
                    <ButtonSliderRTL
                        maxValue={maxValue}
                        minValue={minValue}
                        step={filter.step}
                        currentMaxValue={currentMaxValue}
                        currentMinValue={currentMinValue}
                        setValue={setValue}
                        order={SLIDER_INCREASING}
                        enableMinValue
                        getValueLabel={getValueLabel}
                        setValueLabel={setValueLabel}
                        filterName={filter.label}
                    />
                ) : (
                    <ButtonSlider
                        maxValue={maxValue}
                        minValue={minValue}
                        step={filter.step}
                        currentMaxValue={currentMaxValue}
                        currentMinValue={currentMinValue}
                        setValue={setValue}
                        order={SLIDER_INCREASING}
                        enableMinValue
                        getValueLabel={getValueLabel}
                        setValueLabel={setValueLabel}
                        filterName={filter.label}
                    />
                )}

                <FilterDescription
                    minText={minValueText.replace("{value}", String(minValue))}
                    maxText={maxValueText.replace("{value}", String(maxValue))}
                    minTextDesc={getSliderDescriptionLabel(minValueDesc, minValue)}
                    maxTextDesc={getSliderDescriptionLabel(maxValueDesc, maxValue)}
                    minToMax
                />
            </Flex.Col>
        </Styles.Wrapper>
    );
};

export default MinMaxSliderFilter;
