import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as Type from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import { getBreakpoint, getDirection } from "../../../../../../../common-deprecated/themes/common";
import { focusStyle } from "../../../../../../../common-deprecated/styles/v2/toyota/utilities/Focus";

export const ListItem = styled.div``;

export const Wrapper = styled.details`
    position: relative;
    width: 100%;
    background: ${theme.colors.primaryWhite};

    @media ${getBreakpoint("up", "lg")} {
        width: 100%;
    }
`;

export const DropdownContent = styled.div`
    margin: 0;
    padding: 0 0 ${theme.space[2]}px;
    text-align: ${getDirection("left")};
`;

export const Header = styled.summary<{ show?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: ${theme.space[2]}px 0;
    border-bottom: 1px solid ${({ show }) => (show ? "transparent" : theme.colors.grey2)};
    cursor: pointer;

    &::-webkit-details-marker {
        display: none;
    }

    ${Type.Text} {
        margin-bottom: 0 !important; /* Override CSS spacing */
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-transform: none;
    }

    @media ${getBreakpoint("up", "lg")} {
        border-bottom: 1px solid ${theme.colors.grey2};
    }

    ${focusStyle};
`;
