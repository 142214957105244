import styled from "styled-components";
import { DropdownChildWrapper } from "../../../../../../../common-deprecated/components/dropdown/DropdownContentStyles";
import { IconWrapper } from "../../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getBreakpoint } from "../../../../../../../common-deprecated/themes/common";

export const Wrapper = styled.div`
    padding: 0 ${theme.space[3]}px;

    ${DropdownChildWrapper} {
        padding: ${theme.space[3]}px 0;
    }

    @media ${getBreakpoint("up", "lg")} {
        padding: 0 44px;
    }

    @media ${getBreakpoint("down", "lg")} {
        width: 100%;
    }
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.space[2]}px 0 ${theme.space[1]}px;
    cursor: pointer;
    ${IconWrapper} {
        color: ${theme.colors.primaryBlack};
    }
`;

export const Body = styled.div`
    padding: ${theme.space[3]}px 0;
`;
