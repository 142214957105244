import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../redux/store";
import FilterDropdown from "./FilterDropdown";
import * as Styles from "./styles/FilterListStyles";
import CarTypeFilterEnum from "./car-type/CarTypeFilter";
import FilterWrapper from "./FilterWrapper";
import { trackOpenFilter } from "../../../utils/tracking";
import { isCarType } from "../../../utils/filters";
import { ModelFilterId } from "../../../utils/constants/filterConstants";
import { setActiveFilters } from "../../../redux/actions/ModelFiltersActions";

const FilterList = (): JSX.Element => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const modelFilters = useModelFilterSelector((state) => state.modelFilters);
    const activeFilterIds = modelFilters.activeFilters;
    const carTypeFilterId = ModelFilterId.CarType;

    // Calculate and sort the filters that should be rendered.
    // Car Type is always excluded from this list as it is always rendered first and has custom render logic.
    const activeFilters = Object.values(ModelFilterId)
        .filter((filterId) => modelFilters[filterId].show && !isCarType(filterId))
        .sort((a, b) => modelFilters[a].index - modelFilters[b].index);

    // Key is used as a memo argument to determine if the component needs rerendering.
    const activeFiltersKey = Object.keys(activeFilters).sort().join();

    // Add or remove the filter from the active filter state on click.
    const dropdownClick = (filterId: ModelFilterId) => () => {
        const isActive = activeFilterIds.includes(filterId);
        if (isActive) {
            dispatch(setActiveFilters(activeFilterIds.filter((id) => id !== filterId)));
        } else {
            trackOpenFilter(modelFilters[filterId].label, true);
            dispatch(setActiveFilters(activeFilterIds.concat([filterId])));
        }
    };

    return useMemo(() => {
        return (
            <Styles.Wrapper role="list">
                {modelFilters.modelType.show && (
                    <FilterDropdown
                        show={activeFilterIds.includes(carTypeFilterId)}
                        onClick={dropdownClick(carTypeFilterId)}
                        title={modelFilters[carTypeFilterId].label}
                    >
                        <CarTypeFilterEnum />
                    </FilterDropdown>
                )}

                {activeFilters.map((filterId) => (
                    <FilterDropdown
                        show={activeFilterIds.includes(filterId)}
                        onClick={dropdownClick(filterId)}
                        title={modelFilters[filterId].label}
                        key={filterId}
                    >
                        <FilterWrapper filterId={filterId} />
                    </FilterDropdown>
                ))}
            </Styles.Wrapper>
        );
    }, [activeFiltersKey, activeFilterIds, carTypeFilterId, dropdownClick, modelFilters, activeFilters]);
};

export default FilterList;
