import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { ModelFilterDispatchType, useModelFilterSelector } from "../redux/store";
import { fetchModelResults } from "../redux/actions/ModelResultsActions";
import { objectToQueryString } from "../../../../common-deprecated/utils";
import { isMasterOrSlave } from "../../../../common-deprecated/settings/utils/commonSettingUtils";

/**
 * Used in ModelFilter.
 */
const useModelFilter = (): void => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);
    const resultsInitialized = useModelFilterSelector((state) => state.modelResults.initialized);

    // initial results fetch
    useEffect(() => {
        // If the component is not initialized it means we need to load initial data.
        // Since OR-5232 this should no longer happen, still leaving this here in case of any further changes over time.
        if (!resultsInitialized) {
            dispatch(fetchModelResults(false));
        }
    }, []);

    // This is a quick fix for the Salesman application (OR-7506) migrated over from the old model-overview component (SalesmanRedirectMiddleware)
    // It will use the carconfigSalesmanUrl from the retailer-apps-settings kong endpoint and amend the tyCode, next the middleware redirected to the standalone build.
    // Since server v2 does not support custom middlewares we opted for an even dirtier fix. An improvement ticket was logged so we can clean this up: OR-7539
    useEffect(() => {
        const tyCode = commonSettings.query.tyCodePersonal || commonSettings.query.tyCode;
        if (isMasterOrSlave(commonSettings) && tyCode) {
            window.location.href = `build-and-buy?path=summary&${objectToQueryString(commonSettings.query)}`;
        }
    }, []);
};
export default useModelFilter;
