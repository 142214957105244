import styled from "styled-components";
import { theme } from "../../../../../../../styles/v2/toyota/theme/toyotaTheme";

const trackHeight = 4;
export const buttonSize = 28;

// Some explanation on the width/margins here:
// The ButtonSlider component uses the HTML width of the Slider div for calculations and positioning.
// As the calculations are done based on the "center" of a handle we need the Slider div to match the actual scrollable area.
// This is why we have the buttonSize / 2 left/right margin as that is "unreachable" area for the center of the buttons.
// However, visually it is a lot nicer if the slider background stretches to the full width without the margins, that is why we updated the ::after to add that "unreachable" area
const Slider = styled.div`
    position: relative;
    height: ${trackHeight}px;
    margin: 20px ${buttonSize / 2}px 25px;

    &::after {
        position: absolute;
        top: 0;
        left: -${buttonSize / 2}px;
        width: calc(100% + ${buttonSize}px);
        height: ${trackHeight}px;
        content: "";
        background-color: ${theme.colors.grey2};
        border-radius: 2px;
    }
`;

export const Value = styled.div`
    position: relative;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    user-select: none;
`;

export const Handle = styled.button.attrs<{ left: number }>(({ left }) => ({
    style: {
        left: `${left - buttonSize / 2}px`,
    },
}))<{ left: number }>`
    position: absolute;
    top: -${buttonSize / 2 - 2}px;
    width: ${buttonSize}px;
    height: ${buttonSize}px;
    background: ${theme.colors.primaryWhite};
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.44);
    z-index: 10;
`;

export const Track = styled.div.attrs<{ left: string; width: string }>(({ left, width }) => ({
    style: { left, width },
}))<{ left: string; width: string }>`
    position: absolute;
    top: 0;
    height: ${trackHeight}px;
    background: ${theme.colors.primaryBlack};
    border: none;
    border-radius: 2px;
    z-index: 1;
`;

export default Slider;
