import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { ModelFilterDispatchType, useModelFilterSelector } from "../redux/store";
import { LabelReturnType } from "../../../../shared-logic/features/filters/utils/filterLabelUtils";
import { getFilterReset, getModelFilterLabels } from "../utils/filters";
import { ModelFilterId } from "../utils/constants/filterConstants";

type UseSentenceLabelType = {
    midLabel: LabelReturnType;
    removeFilter: () => void;
    isActive: boolean;
    filterCount: number; // Filter count is currently 1 for min max sliders even if both min and max are set. Seems like the most logical solution.
};

/**
 * Get an object containing labels, actions and derived properties related to the filter.
 */
const useFilterLabels = (filterId: ModelFilterId): UseSentenceLabelType => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const cultureName = useModelFilterSelector((state) => state.commonSettings.culture.name);
    const filters = useModelFilterSelector((state) => state.modelFilters);
    const isActive = filters[filterId].active;
    const { midLabel, filterCount } = getModelFilterLabels(filterId, filters, cultureName);
    const removeFilter = (): void => getFilterReset(filterId, dispatch);

    return useMemo(
        () => ({
            midLabel,
            removeFilter,
            isActive,
            filterCount,
        }),
        [midLabel, isActive, filterCount], // eslint-disable-line react-hooks/exhaustive-deps
    );
};

export default useFilterLabels;
