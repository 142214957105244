import styled from "styled-components";
import CommonLabel from "../../../../../../../../common-deprecated/styles/v2/toyota/components/Label";
import * as Button from "../../../../../../../../common-deprecated/styles/v2/toyota/components/Button";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Label = styled(CommonLabel)`
    margin-top: 0;
    color: ${theme.colors.primaryBlack};
`;

export const SearchTextButton = styled(Button.Primary)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 14px 10px 16px;
    line-height: 2.4rem;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    &:hover {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }
`;
