import styled, { css } from "styled-components";
import { getDirection, getBreakpoint } from "../../../../../../../../common-deprecated/themes/common";
import * as Pill from "../../../../../../../../common-deprecated/styles/v2/toyota/components/Pill";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { IconWrapper } from "../../../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";

// Because of the way the isFixed logic works we need to know the height of the filter static bar.
// If this ever needs to be dynamic this needs to be calculated with React refs.
export const FILTER_BAR_HEIGHT = 60;

// Using sticky means IE users don't get the sticky filter bar, but that doesn't seem like a breaking issue.
export const Wrapper = styled.div<{ isFixed?: boolean }>`
    display: flex;
    position: sticky;
    top: var(--visibleHeaderHeight, 0);
    inset-inline-start: 0px;
    height: ${FILTER_BAR_HEIGHT}px;
    padding: 0;
    background-color: ${theme.colors.primaryBlack};
    /* Transition of top should match GFIs implementation */
    transition: border-bottom-color 0.3s, top 0.3s ease-out, height 0.15s;
    z-index: 1000;

    @supports not (inset-inline-start: 0) {
        ${getDirection("left")}: 0;
    }

    @media ${getBreakpoint("up", "lg")} {
        display: none;
        background-color: ${theme.colors.primaryWhite};
    }

    ${Pill.Wrapper} {
        display: none;
        align-self: center;
        height: 40px;
        margin: 0;
    }

    ${Pill.Text} {
        @media ${getBreakpoint("down", "md")} {
            width: 75px;
            min-width: auto;
            max-width: 100px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    ${({ isFixed }) =>
        isFixed &&
        css`
            height: ${FILTER_BAR_HEIGHT}px;
            z-index: ${theme.zIndex.sticky};
            ${Pill.Wrapper} {
                display: flex;
            }
        `};
`;

export const Inner = styled.div<{ isFixed: boolean }>`
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    background-color: ${theme.colors.primaryBlack};

    @media ${getBreakpoint("up", "lg")} {
        max-width: 1170px;
        background-color: ${theme.colors.primaryWhite};
    }
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    border-${getDirection("right")}: 1px solid ${theme.colors.grey7};

    &:last-child {
        border-${getDirection("right")}: none;
    }
`;

export const Button = styled.button`
    display: flex;
    width: 100%;
    padding: 0 ${theme.space[3]}px;
    color: ${theme.colors.primaryWhite};

    @media ${getBreakpoint("up", "lg")} {
        display: none;
    }
`;

export const ButtonLabel = styled.span`
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ButtonIcon = styled.span`
    display: flex;
    align-items: center;
    margin-left: auto;

    ${IconWrapper} {
        display: flex;
        align-items: center;
    }
`;

export const ButtonIconLabel = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: ${theme.space[1]}px;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    color: ${theme.colors.highlightBlack};
    background-color: ${theme.colors.primaryWhite};
    border-radius: 2px;
`;

export const SortFilters = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
`;
