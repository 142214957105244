import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "styled-components";
import Globalize from "../../../common-deprecated/Globalize";
import getTheme from "../../../common-deprecated/themes/getTheme";
import { modelFilterClientStore, ModelFilterStateType } from "./redux/store";
import InitializeSalesman from "../../../common-deprecated/InitializeSalesman";
import { renderReact } from "../../../common-deprecated/utils/clientRenderUtils";
import { clearSelectedFilters } from "./utils/localStorage";
import { ModalProvider } from "../../../common-deprecated/containers/ModalProvider";
import { WindowDimensionsProvider } from "../../../common-deprecated/containers/WindowDimensionsProvider";
import { isRetailer, removeLastSlashAndWhiteSpace } from "../../../common-deprecated/utils";

export const getInit =
    (ModelFilter: React.ElementType) =>
    (target: string, props: unknown, initialState: ModelFilterStateType): void => {
        const { commonSettings } = initialState;
        const { country, language, culture, currencyMultiplier } = commonSettings;

        // eslint-disable-next-line camelcase
        __webpack_public_path__ = `${removeLastSlashAndWhiteSpace(commonSettings.resourcePath)}/`;

        // Initialize store, tagging and globalize.
        const store = modelFilterClientStore(initialState, commonSettings.component);
        if (!isRetailer(commonSettings)) {
            InitializeSalesman(commonSettings, store);
        }

        // If there are no active filters, reset the local storage
        if (!initialState.modelFilters.hasActiveFilters) {
            clearSelectedFilters({ country, language });
        }
        Globalize(country, culture, currencyMultiplier);

        renderReact(
            target,
            commonSettings,
            <ReduxProvider store={store}>
                <ThemeProvider theme={getTheme(commonSettings)}>
                    <WindowDimensionsProvider>
                        <ModalProvider>
                            <ModelFilter />
                        </ModalProvider>
                    </WindowDimensionsProvider>
                </ThemeProvider>
            </ReduxProvider>,
        );
    };
