import { ModelFilterSortOrder } from "../../utils/constants/filterConstants";
import { ModelResultType } from "../../../../../shared-logic/features/filters/utils/constants/resultConstants";

// ----------------------------------------------------------------------
// Car actions
// ----------------------------------------------------------------------
export const FETCH_MODEL_RESULTS = "modelResults/fetch";
export const SET_MODEL_RESULTS_ORDER = "modelResults/setOrder";

export type FetchModelResultsType = {
    type: typeof FETCH_MODEL_RESULTS;
    forPlaceholders: boolean;
    includeActiveAggregations: boolean;
};

export type SetModelResultsOrder = { type: typeof SET_MODEL_RESULTS_ORDER; order: ModelFilterSortOrder };

// Start retrieving car results based on the current filter setup. (actual fetching load should happen in logic)
// forPlaceholders is used when placeholders for the to be fetched cars should be visible.
export const fetchModelResults = (
    forPlaceholders: boolean = false,
    includeActiveAggregations: boolean = false,
): FetchModelResultsType => ({
    type: FETCH_MODEL_RESULTS,
    forPlaceholders,
    includeActiveAggregations,
});

export const setModelResultsOrder = (order: ModelFilterSortOrder): SetModelResultsOrder => ({
    type: SET_MODEL_RESULTS_ORDER,
    order,
});

// ----------------------------------------------------------------------
// Filter updates
// ----------------------------------------------------------------------
export const FILTER_MODEL_RESULTS = "modelResults/filter";
export const SET_MODEL_RESULTS = "modelResults/set";

export type FilterModelResultsType = {
    type: typeof FILTER_MODEL_RESULTS;
    noTracking: boolean;
    causedByMainFilter: boolean;
};

export type SetModelResultsType = {
    type: typeof SET_MODEL_RESULTS;
    results: ModelResultType[];
    totalResultCount: number;
};

// Triggered whenever a filter update happens. Converts current results to placeholders and should trigger a refetch.
// causedByMainFilter param is used for tracking purposes.
export const filterModelResults = (
    noTracking: boolean = false,
    causedByMainFilter: boolean = false,
): FilterModelResultsType => ({
    type: FILTER_MODEL_RESULTS,
    noTracking,
    causedByMainFilter,
});

// Replace all cars in state with the provided results.
export const setModelResults = (results: ModelResultType[], totalResultCount: number): SetModelResultsType => ({
    type: SET_MODEL_RESULTS,
    results,
    totalResultCount,
});

export type ModelResultsActionsType =
    | FetchModelResultsType
    | SetModelResultsOrder
    | FilterModelResultsType
    | SetModelResultsType;
