import React from "react";
import { useDispatch } from "react-redux";
import { ModelFilterDispatchType, useModelFilterSelector } from "../../../../redux/store";
import MCFilterView from "./MCFilterView";
import { setSelectMultiFilter } from "../../../../redux/actions/ModelFiltersActions";
import { sortArrayByLabel } from "../../../../../../../shared-logic/features/filters/utils/filters";
import { MultipleChoiceModelFilterIdType } from "../../../../utils/constants/filterConstants";
import { mergeMultipleChoiceValueTypeByLabel } from "../../../../../../../shared-logic/features/filters/utils/filterLabelUtils";

/**
 * Default multiple choice filter. Not used for edge cases like used car type filter etc.
 */
const DefaultMCFilter = ({
    filterId,
    mergeByLabel = false,
}: {
    filterId: MultipleChoiceModelFilterIdType;
    mergeByLabel?: boolean;
}): JSX.Element => {
    const filterValues = useModelFilterSelector((state) =>
        mergeByLabel
            ? mergeMultipleChoiceValueTypeByLabel(state.modelFilters[filterId].values)
            : state.modelFilters[filterId].values,
    );
    const dispatch = useDispatch<ModelFilterDispatchType>();
    return (
        <MCFilterView
            id={filterId}
            columnWidth={1}
            values={sortArrayByLabel(filterValues)}
            selectValue={(valueId, selected) =>
                dispatch(setSelectMultiFilter(mergeByLabel ? valueId.split("|") : [valueId], selected, filterId))
            }
        />
    );
};

export default DefaultMCFilter;
