import styled, { css } from "styled-components";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";
import { invertGradient, isLightColour } from "../../../../../../../common-deprecated/utils";

// Default white background with a small box shadow.
export const Shadow = styled.div`
    background: ${(props) => props.theme.color.lightest};
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

// Default grade page gradient background style.
export type GradientStylesProps = { baseColour: string };
export const gradientStyles = css<GradientStylesProps>`
    ${({ baseColour, theme }) =>
        css`
            background: ${isLightColour(baseColour) ? `${theme.color.lightest} !important` : baseColour};
            color: ${isLightColour(baseColour) ? theme.color.dark : theme.color.lightest};
        `};

    &::after {
        position: absolute;
        top: 0;
        ${getDirection("left")}: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: ${(props) =>
            invertGradient(props.baseColour)
                ? "linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)"
                : "linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%)"};
        z-index: 1;
    }
`;
