import React, { useRef } from "react";
import FocusTrap from "focus-trap-react";
import { useModelFilterSelector } from "../../../../redux/store";
import Icon from "../../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { ModelFilterLabel, sortOrderLabelMap, useModelFilterLabel } from "../../../../utils/labels";
import * as PillStyles from "../../../../../../../common-deprecated/styles/v2/toyota/components/Pill";
import { IconWrapper } from "../../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import * as Styles from "./styles/SortFiltersStyles";
import * as FilterBarStyles from "./styles/StaticFilterBarStyles";
import * as PopupListStyles from "./styles/PopupListStyles";
import useSortFilters from "../../../../hooks/useSortFilters";
import { useUniqueId } from "../../../../../../../common-deprecated/hooks";

type SortFilterPropsType = {
    topOffset?: number;
    isFixed?: boolean;
};
const SortFilters = (props: SortFilterPropsType): JSX.Element => {
    const { topOffset, isFixed } = props;
    const sortOrder = useModelFilterSelector(({ modelResults }) => modelResults.sortOrder);
    const buttonLabel = useModelFilterLabel(sortOrderLabelMap[sortOrder]);

    const popupRef = useRef<HTMLDivElement>(null);
    const { showPopup, togglePopup, items } = useSortFilters(popupRef);

    const uniqueId = useUniqueId();

    return (
        <Styles.Wrapper ref={popupRef}>
            <Styles.Pill>
                <PillStyles.Wrapper
                    type="button"
                    aria-labelledby={`button-sort-filter-label-${uniqueId}`}
                    aria-haspopup="true"
                    hasIcon
                    onClick={togglePopup}
                >
                    <IconWrapper iconColor="primaryBlack" aria-hidden="true">
                        <Icon variant="sort" />
                    </IconWrapper>
                    <PillStyles.Text id={`button-sort-filter-label-${uniqueId}`}>{buttonLabel}</PillStyles.Text>
                </PillStyles.Wrapper>
            </Styles.Pill>
            <FilterBarStyles.Button
                type="button"
                aria-labelledby={`button-mobile-sort-filter-label-${uniqueId}`}
                aria-haspopup="true"
                onClick={togglePopup}
            >
                <FilterBarStyles.ButtonLabel id={`button-mobile-sort-filter-label-${uniqueId}`}>
                    {buttonLabel}
                </FilterBarStyles.ButtonLabel>
                <FilterBarStyles.ButtonIcon aria-hidden="true">
                    <Icon variant="sort" />
                </FilterBarStyles.ButtonIcon>
            </FilterBarStyles.Button>

            {showPopup && (
                <FocusTrap>
                    <Styles.Popup id="menu" role="menu" top={topOffset} isFixed={isFixed}>
                        {items.map(({ id, labelKey, onClick }) => (
                            <PopupListStyles.ItemRow key={id}>
                                <PopupListStyles.Item>
                                    <PopupListStyles.Button type="button" role="menuitem" onClick={onClick}>
                                        <ModelFilterLabel label={labelKey} />
                                    </PopupListStyles.Button>
                                </PopupListStyles.Item>
                            </PopupListStyles.ItemRow>
                        ))}
                    </Styles.Popup>
                </FocusTrap>
            )}
        </Styles.Wrapper>
    );
};

export default SortFilters;
