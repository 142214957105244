import styled from "styled-components";
import * as MinMaxStyles from "../../min-max/styles/MinMaxStyles";

export const Wrapper = styled(MinMaxStyles.Wrapper)``;

export const Result = styled(MinMaxStyles.Result)``;

export const Label = styled(MinMaxStyles.Label)``;

export const Value = styled(MinMaxStyles.Value)<{ hasLongText?: boolean }>`
    font-size: ${({ hasLongText }) => (hasLongText ? 1.6 : 1.8)}rem;
`;
