import { useEffect, useRef, useState } from "react";
import { useAemNavOffset, useDisableBodyScroll } from "../../../../common-deprecated/hooks";
import { useBreakpoint } from "../../../../common-deprecated/themes/common";

/**
 * Hook logic for the car-filter/model-filter sidebar.
 */
const useFilterSidebar = (
    showMoreFilters: boolean,
    showMoreFiltersQuery: boolean | undefined,
    resultsInitialized: boolean,
    showMoreFiltersDispatchFunction: () => void,
    hideFiltersByDefault?: boolean,
): {
    navOffset: number;
    absoluteSidebar: boolean;
    setAbsoluteSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    enableSidebarTransition: boolean;
} => {
    const [absoluteSidebar, setAbsoluteSidebar] = useState<boolean>(true);
    const [enableSidebarTransition, setEnableSidebarTransition] = useState<boolean>(false);

    const navOffset = useAemNavOffset();

    const isFixed = useBreakpoint("down", "lg");
    useDisableBodyScroll(!(isFixed && showMoreFilters));

    const previousShow = useRef<boolean>(showMoreFilters);
    useEffect(() => {
        if (previousShow.current && !showMoreFilters) {
            setAbsoluteSidebar(true);
        }
        previousShow.current = showMoreFilters;
    }, [showMoreFilters]);

    /**
     * A summary of how/why the logic below is implemented (OR-4999 + OR-4823):
     *
     * FilterSidebar is used on both mobile and desktop views as it is exactly the same component in both cases.
     * On desktop the sidebar should always be shown by default except if the showMoreFilters query param is false.
     * However for mobile this logic is irrelevant as the sidebar is fullscreen so it should always be hidden by default.
     * For various other reasons the showMoreFilters property is in Redux and is shared across mobile and desktop versions.
     *
     * To get proper behaviour on init we need client side logic (useBreakpoint) to determine if we're on desktop or mobile.
     * Once we have determined this we can check if we actually need to show the sidebar by default by checking the showMoreFilters query param.
     *
     * To get a nice init visual we 'sync' the initial show of the sidebar with the init of the model results.
     * This means that we only have one initial component "transition" (loader => model results) instead of two (loader => show sidebar => show model results).
     * We also use the enableSidebarTransition prop to only enable the sidebar animation after this init logic as there is no use having it on init.
     */
    const isMobile = useBreakpoint("down", "lg");
    const initialized = useRef<boolean>(false);

    useEffect(() => {
        if (isMobile || !resultsInitialized) return;

        if (!initialized.current) {
            // We're on desktop, model-filter results are initialized, check if we need to show the filter side bar.
            if (
                (!hideFiltersByDefault && showMoreFiltersQuery !== false) || // Explicitly check for "false" as the query is used to specifically hide the sidebar by default.
                (hideFiltersByDefault && showMoreFiltersQuery)
            ) {
                showMoreFiltersDispatchFunction();
                // Make sure the sidebar is not absolute as their will be no transition on init
                setAbsoluteSidebar(false);
            }

            initialized.current = true;

            // Enable the CSS sidebar transition but only after the next render.
            window.requestAnimationFrame(() => {
                setEnableSidebarTransition(true);
            });
        }
    }, [showMoreFiltersQuery, isMobile, resultsInitialized, hideFiltersByDefault]);

    return { navOffset, absoluteSidebar, setAbsoluteSidebar, enableSidebarTransition };
};

export default useFilterSidebar;
