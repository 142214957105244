import styled, { css } from "styled-components";
import { DropdownChildWrapper } from "../../../../../../../../common-deprecated/components/dropdown/DropdownContentStyles";
import { getBreakpoint, getDirection } from "../../../../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as Button from "../../../../../../../../common-deprecated/styles/v2/toyota/components/Button";
import { focusStyle } from "../../../../../../../../common-deprecated/styles/v2/toyota/utilities/Focus";

// ----------------------------------------------------------------------
// General
// ----------------------------------------------------------------------
export const Wrapper = styled.div<{ left: number; top: number }>`
    position: absolute;
    width: 375px;
    padding: 0 ${theme.space[3]}px;
    background: ${theme.colors.primaryWhite};
    border-radius: 8px;
    box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.15);
    z-index: ${theme.zIndex.popover + 1};

    ${({ top, left }) => css`
        top: ${top}px;
        left: ${left}px;
    `}

    ${DropdownChildWrapper} {
        background-color: ${theme.colors.primaryWhite};
    }

    @media ${getBreakpoint("down", "md")} {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
`;

export const Body = styled.div`
    padding: ${theme.space[4]}px 0;
    background-color: ${theme.colors.primaryWhite};

    @media ${getBreakpoint("down", "md")} {
        height: calc(100% - 60px); /* Height viewport (100%) - height Header (62px) */
        overflow-y: auto;
    }
`;

// ----------------------------------------------------------------------
// Header
// ----------------------------------------------------------------------
export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - ${theme.space[3] * 2}px);
    margin: 0 ${theme.space[3]}px;
    padding: ${theme.space[2]}px 0;
    border-bottom: 1px solid ${theme.colors.grey2};

    ${Button.Primary} {
        height: 4rem;
        margin: 0;
        line-height: 4rem;
    }

    ${Button.Link} {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    @media ${getBreakpoint("up", "lg")} {
        padding: ${theme.space[2]}px 0;
    }
`;

export const ClearButton = styled.button`
    display: flex;
    align-items: center;
    max-width: 175px;
    height: 100%; /* Added full height for extra click usability. Button is "hidden" anyway. */
    padding: 0;
    background: ${theme.colors.primaryWhite};
    border: none;

    ${focusStyle};
`;

export const ClearText = styled.span`
    margin-${getDirection("left")}: 12px;
    text-align: ${getDirection("left")};
    text-overflow: ellipsis;
    overflow: hidden;
    /* stylelint-disable */
    display: -webkit-box;
    width: 120px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* stylelint-enable */
`;

// ----------------------------------------------------------------------
// Footer
// ----------------------------------------------------------------------
export const Footer = styled.div`
    padding: 0 ${theme.space[3]}px ${theme.space[3]}px;
`;

export const ResultCount = styled.span`
    font-family: ${theme.fonts.regular};
    font-weight: normal;
`;
