import React, { useEffect, useMemo, useRef } from "react";
import useFilterLabels from "../../../../hooks/useFilterLabels";
import { ModelFilterLabel } from "../../../../utils/labels";
import FilterPillButton from "../../common/FilterPillButton";
import * as Styles from "./styles/ActiveFilterSliderStyles";
import { ModelFilterId } from "../../../../utils/constants/filterConstants";
import { PosType } from "../../../../../../../common-deprecated/types/CommonTypes";

type ActiveFilterSliderPillType = {
    filterId: ModelFilterId;
    showFilter?: (pos: PosType) => void;
    update?: () => void;
    activeFilter?: ModelFilterId | null;
};

const ActiveFilterSliderPill = ({
    filterId,
    showFilter,
    update,
    activeFilter,
}: ActiveFilterSliderPillType): JSX.Element => {
    const { midLabel, removeFilter, isActive } = useFilterLabels(filterId);
    const filterContainerRef = useRef<HTMLDivElement>(null);

    // This is a shortcut to support rerendering the parent container when the size of this component changes.
    // This is required because of the way the slider is implemented, and it seems like this is the easiest way to do it.
    useEffect(() => update && update(), [midLabel, update]);
    return useMemo(
        () => (
            <Styles.PillWrapper ref={filterContainerRef}>
                <FilterPillButton
                    isSelected={activeFilter === filterId}
                    closeFn={isActive ? removeFilter : undefined}
                    onClick={(pos) => {
                        if (showFilter) showFilter(pos);
                    }}
                >
                    {isActive ? (
                        <>
                            {midLabel.map(({ text, strikethrough }, index) => {
                                const parsedText = text.replace(/\s/g, "&nbsp;");
                                return (
                                    <Styles.PillText
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${text}${index}`}
                                        strikethrough={strikethrough}
                                        dangerouslySetInnerHTML={{ __html: parsedText }}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {ModelFilterId.CarType === filterId && <ModelFilterLabel label="typePlus" />}
                            {ModelFilterId.Price === filterId && <ModelFilterLabel label="pricePlus" />}
                        </>
                    )}
                </FilterPillButton>
            </Styles.PillWrapper>
        ),
        [midLabel, removeFilter],
    );
};

export default ActiveFilterSliderPill;
