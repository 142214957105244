import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getBreakpoint } from "../../../../../../../common-deprecated/themes/common";

export const Wrapper = styled.div`
    grid-column: 1 / 1;
    text-align: center;

    @media ${getBreakpoint("up", "md")} {
        grid-column: 1 / 3;
    }

    @media ${getBreakpoint("up", "lg")} {
        grid-column: 1 / 4;
    }
`;

export const NoResultTextWrapper = styled.div`
    margin: ${theme.space[6]}px 0 ${theme.space[5]}px;
    text-align: center;
`;
