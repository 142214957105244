import styled from "styled-components";
import { theme } from "../theme/toyotaTheme";

const Label = styled.label.attrs(() => ({
    className: "t-zeta-text",
}))`
    display: block;
    width: 100%;
    color: ${theme.colors.grey3};
`;

export default Label;
