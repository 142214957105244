import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getBreakpoint, getDirection } from "../../../../../../../common-deprecated/themes/common";
import * as Button from "../../../../../../../common-deprecated/styles/v2/toyota/components/Button";
import { focusStyle } from "../../../../../../../common-deprecated/styles/v2/toyota/utilities/Focus";
import { AvailabilityBadges } from "../../../../../../../common-deprecated/components/aem/toyota/availability-badges/styles/AvailabilityBadgesStyles";
import InternalLink from "../../../../../../../common-deprecated/components/styles/InternalLinkStyles";

export const Results = styled.div`
    width: 100%;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    width: 100%;

    ${InternalLink} {
        height: 100%;
        padding: 0;
        border: none;
    }

    @media ${getBreakpoint("up", "md")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media ${getBreakpoint("up", "lg")} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

export const Container = styled.div<SpaceProps>`
    position: relative;
    ${space}
    @media ${getBreakpoint("down", "lg")} {
        padding-top: ${theme.space[3]}px;
        border-top: 1px solid ${theme.colors.grey2};
    }
`;

export const Wrapper = styled.article<{ hasRightBorder?: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.space[5]}px ${theme.space[3]}px;

    @media ${getBreakpoint("up", "md")} {
        border-bottom: 1px solid ${theme.colors.grey2};
        border-${getDirection("right")}: 1px solid ${theme.colors.grey2};
    }

    @media ${getBreakpoint("only", "md")} {
        /* 2nd column */
        &:nth-child(3n - 1) {
            border-${getDirection("right")}: none;
        }
    }

    @media ${getBreakpoint("up", "xl")} {
        /* 1st column */
        &:nth-child(3n - 2) {
            border-${getDirection("right")}: 1px solid ${theme.colors.grey2};
        }

        /* 3rd column */
        &:nth-child(3n) {
            border-${getDirection("right")}: none;
        }
    }

    a {
        text-align: center;
    }

    ${Button.ButtonTertiary} {
        margin-top: auto;
        margin-bottom: ${theme.space[3] / 2}px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${AvailabilityBadges} {
        > ul {
            > li {
                width: 100%;
                text-align: center;
            }
        }
    }
`;

export const BadgesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${theme.space[1]}px;
    width: 100%;

    &:not(:empty) {
        margin-top: ${theme.space[1]}px;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

export const ImageWrapper = styled.div`
    margin-top: auto;
`;

export const RedirectWrapper = styled.a`
    &:hover {
        color: unset;
    }
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 ${theme.space[3]}px;
`;

export const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @media ${getBreakpoint("up", "lg")} {
        flex-wrap: nowrap;
    }
`;

export const Title = styled.h5`
    &,
    a {
        margin-bottom: ${theme.space[1]}px;
        font-family: ${theme.fonts.regular};
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
`;

export const GRLogo = styled.div`
    position: absolute;
    top: ${theme.space[3]}px;
    ${getDirection("right")}: ${theme.space[3]}px;
    width: 50px;

    @media ${getBreakpoint("up", "md")} {
        top: ${theme.space[2]}px;
    }

    @media ${getBreakpoint("up", "xl")} {
        top: ${theme.space[4]}px;
        ${getDirection("right")}: ${theme.space[4]}px;
    }
`;

export const GradesLink = styled(Button.Link)`
    display: flex;
    margin-bottom: 0;
    color: ${theme.colors.primaryBlack};

    ${focusStyle};
`;

export const EcoTag = styled.span`
    font-family: ${theme.fonts.semiBold};
    font-size: 1.3rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: ${theme.colors.hybrid};
`;
