import { ModelFiltersReducerType } from "../redux/reducers/ModelFiltersReducer";
import { ModelFilterActionsType } from "../redux/store";
import {
    setCarType,
    setPrice,
    setPriceActive,
    setSelectMultiFilter,
    setSliderValue,
    showFilter,
} from "../redux/actions/ModelFiltersActions";
import Debug from "../../../../common-deprecated/Debug";
import { decodeQueryValue, minMaxValueIsInvalid } from "../../../../shared-logic/features/filters/utils/params";
import {
    CarTypeFilterEnum,
    CarTypeFilterType,
    COMMERCIAL_FILTER_CODE,
} from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { lcvFilterIds, ModelFilterId, ModelFilterSortOrder } from "./constants/filterConstants";
import { isCarType, isMinMaxSlider, isMultipleChoice, isPrice } from "./filters";
import { getSelectedCarTypes } from "../../../../shared-logic/features/filters/utils/filters";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { setModelResultsOrder } from "../redux/actions/ModelResultsActions";
import { isValidGuid } from "../../../../common-deprecated/utils";
import { getModelMapValueById } from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { RetailerCarConfigTypes } from "../../../../common-deprecated/features/retailer/utils/constants";

// This whole file is based on the car-filter approach.
export enum ModelFilterQueryParam {
    Equipment = "equipment",
    Seats = "seats",
    Doors = "doors",
    PowerOutput = "powerOutput",
    Price = "price",
    Brand = "brand",
    Model = "model",
    FuelType = "fuelType",
    Transmission = "transmission",
    CarType = "carType",
    FuelConsumption = "fuelConsumption",
    Speed = "speed",
    Luggage = "luggage",
    Co2 = "co2",
    Eta = "eta",
    OnlineSales = "onlineSales",
    TowingCapacity = "towingCapacity",
    LoadVolume = "loadVolume",
    MaximumPayload = "maximumPayload",
}

// This is basically the same as modelFilterQuery Params but defined separately considering these keys are
// consumed by external party. This to avoid accidental refactors on our side.
export enum LocalStorageFilterKey {
    Equipment = "equipment",
    Seats = "seats",
    Doors = "doors",
    PowerOutput = "powerOutput",
    Price = "price",
    Transmission = "transmission",
    FuelType = "fuelType",
    CarType = "carType",
    FuelConsumption = "fuelConsumption",
    Speed = "speed",
    Luggage = "luggage",
    Co2 = "co2",
    Eta = "eta",
    OnlineSales = "onlineSales",
    TowingCapacity = "towingCapacity",
    LoadVolume = "loadVolume",
    MaximumPayload = "maximumPayload",
}

export const modelFilterQueryParamsMap: Record<ModelFilterQueryParam, ModelFilterId> = {
    [ModelFilterQueryParam.Equipment]: ModelFilterId.Equipment,
    [ModelFilterQueryParam.Seats]: ModelFilterId.Seats,
    [ModelFilterQueryParam.Doors]: ModelFilterId.Doors,
    [ModelFilterQueryParam.PowerOutput]: ModelFilterId.PowerOutput,
    [ModelFilterQueryParam.Price]: ModelFilterId.Price,
    [ModelFilterQueryParam.Brand]: ModelFilterId.CarType,
    [ModelFilterQueryParam.Model]: ModelFilterId.CarType,
    [ModelFilterQueryParam.Transmission]: ModelFilterId.Transmission,
    [ModelFilterQueryParam.FuelType]: ModelFilterId.CarType,
    [ModelFilterQueryParam.CarType]: ModelFilterId.CarType,
    [ModelFilterQueryParam.FuelConsumption]: ModelFilterId.FuelConsumption,
    [ModelFilterQueryParam.Speed]: ModelFilterId.Speed,
    [ModelFilterQueryParam.Luggage]: ModelFilterId.Luggage,
    [ModelFilterQueryParam.Co2]: ModelFilterId.Co2,
    [ModelFilterQueryParam.Eta]: ModelFilterId.Eta,
    [ModelFilterQueryParam.OnlineSales]: ModelFilterId.OnlineSales,
    [ModelFilterQueryParam.TowingCapacity]: ModelFilterId.TowingCapacity,
    [ModelFilterQueryParam.LoadVolume]: ModelFilterId.LoadVolume,
    [ModelFilterQueryParam.MaximumPayload]: ModelFilterId.MaximumPayload,
};

export const localStorageModelFilterMap = {
    [ModelFilterId.Equipment]: LocalStorageFilterKey.Equipment,
    [ModelFilterId.Seats]: LocalStorageFilterKey.Seats,
    [ModelFilterId.Doors]: LocalStorageFilterKey.Doors,
    [ModelFilterId.PowerOutput]: LocalStorageFilterKey.PowerOutput,
    [ModelFilterId.Price]: LocalStorageFilterKey.Price,
    [ModelFilterId.CarType]: LocalStorageFilterKey.CarType,
    [ModelFilterId.FuelConsumption]: LocalStorageFilterKey.FuelConsumption,
    [ModelFilterId.Transmission]: LocalStorageFilterKey.Transmission,
    [ModelFilterId.Speed]: LocalStorageFilterKey.Speed,
    [ModelFilterId.Luggage]: LocalStorageFilterKey.Luggage,
    [ModelFilterId.Co2]: LocalStorageFilterKey.Co2,
    [ModelFilterId.Eta]: LocalStorageFilterKey.Eta,
    [ModelFilterId.OnlineSales]: LocalStorageFilterKey.OnlineSales,
    [ModelFilterId.TowingCapacity]: LocalStorageFilterKey.TowingCapacity,
    [ModelFilterId.LoadVolume]: LocalStorageFilterKey.LoadVolume,
    [ModelFilterId.MaximumPayload]: LocalStorageFilterKey.MaximumPayload,
} as const;

// Supported body params/query strings.
export type ModelFilterParamType = Partial<
    Record<ModelFilterQueryParam, string> & {
        // Keeps track of the "open" state of the "more filters" slide-in. Not rendered server-side.
        showMoreFilters: boolean;
        // Sort order of the current filter.
        sortOrder: ModelFilterSortOrder;
        // Force all results to link to a specific type of carconfig instead of the automatic rollout detection
        overrideCarConfigLink: RetailerCarConfigTypes;
    }
>;

/**
 * Parse query values for a car-type filter.
 */
const parseCarTypeQueryValues = <id extends string, reducer extends Record<id, CarTypeFilterType>>(
    commonSettingsType: CommonSettingsType,
    filters: reducer,
    filterId: id,
    carFilter: CarTypeFilterEnum,
    paramValues: string = "",
): { selected: string[]; deselected: string[] } => {
    const filter = filters[filterId]?.[carFilter];
    if (!filter) return { selected: [], deselected: [] };

    // For some reason the comma gets double encoded, causing OR-3840. this fixes that.
    const decodedParamValues = decodeURIComponent(decodeURI(paramValues));
    const selectedValues = decodedParamValues
        .split(",")
        .map((paramValue) => {
            // Returns modelMap when paramValue is an modelId
            const isGuid = isValidGuid(paramValue);
            if (isGuid) {
                const modelMap = getModelMapValueById(commonSettingsType, paramValue);
                return modelMap?.localCode.toLocaleLowerCase() || paramValue;
            }
            return paramValue.toLocaleLowerCase();
        })
        .filter((paramValue) => {
            return filter.find((value) => value.id.toLocaleLowerCase() === paramValue.toLocaleLowerCase());
        });

    const deSelectedValues = filter
        .filter((value) => value.selected && !selectedValues.includes(value.id))
        .map((value) => value.id);

    return { selected: selectedValues, deselected: deSelectedValues };
};

/**
 * Parse model filter params propagated through GET or POST into filter actions.
 */
export const paramsToModelFilterActions = (
    commonSettingsType: CommonSettingsType,
    params: ModelFilterParamType,
    modelFilters: ModelFiltersReducerType,
): ModelFilterActionsType[] => {
    const actions: ModelFilterActionsType[] = [];

    Object.values(ModelFilterQueryParam)
        .filter((queryParam) => params[queryParam])
        .forEach((queryParam) => {
            const filterId = modelFilterQueryParamsMap[queryParam];
            const filterValue = decodeQueryValue(params[queryParam] || "");
            if (!filterId) {
                Debug.error("Filter in parameters but no filterId found");
                return;
            }

            if (isCarType(filterId)) {
                let filter = CarTypeFilterEnum.Models;
                if (queryParam === ModelFilterQueryParam.FuelType) filter = CarTypeFilterEnum.FuelType;
                if (queryParam === ModelFilterQueryParam.CarType) filter = CarTypeFilterEnum.CarType;

                const { selected, deselected } = parseCarTypeQueryValues(
                    commonSettingsType,
                    modelFilters,
                    filterId,
                    filter,
                    filterValue,
                );
                if (selected.length) {
                    actions.push(setCarType(filterId, filter, selected, true));
                    actions.push(setCarType(filterId, filter, deselected, false));
                    selected.forEach((selectedFilterId) => {
                        const selectedFilters = modelFilters[filterId][filter].find(
                            (subFilter) => subFilter.id === selectedFilterId,
                        );

                        if (selectedFilters?.externalIdentifier === COMMERCIAL_FILTER_CODE) {
                            lcvFilterIds.forEach((id) => {
                                if (modelFilters[id].label) actions.push(showFilter(true, id));
                            });
                        }
                    });
                }
            } else if (isPrice(filterId)) {
                // Determine the active price filter.
                const [priceKey, priceFilter] = filterValue.split(":");
                if (priceKey !== "cash" && priceKey !== "monthly") return;

                const [min, max] = priceFilter.split("-").map((value) => Number(value));
                const { minValue, maxValue } = modelFilters[filterId][priceKey];

                if (minMaxValueIsInvalid(min, max, minValue, maxValue)) return;

                actions.push(setPrice(filterId, "min", min, priceKey));
                actions.push(setPrice(filterId, "max", max, priceKey));
                actions.push(setPriceActive(filterId, priceKey));
            } else if (isMultipleChoice(filterId)) {
                const { values } = modelFilters[filterId];

                // Parse selected values and set in state if they are valid (valid == item found in state).
                const newSelectedValues = filterValue
                    .split(",")
                    .filter((paramValue) => values.find((value) => value.id === paramValue));
                if (newSelectedValues.length) actions.push(setSelectMultiFilter(newSelectedValues, true, filterId));

                // Deselect values not present in current params.
                const deSelectedValues = values
                    .filter((value) => value.selected && !newSelectedValues.includes(value.id))
                    .map((value) => value.id);
                if (deSelectedValues.length) actions.push(setSelectMultiFilter(deSelectedValues, false, filterId));
            } else if (isMinMaxSlider(filterId)) {
                const { minValue, maxValue } = modelFilters[filterId];
                const [min, max] = filterValue.split("-").map((value) => Number(value));

                if (minMaxValueIsInvalid(min, max, minValue, maxValue)) return;

                actions.push(setSliderValue("min", min, filterId));
                actions.push(setSliderValue("max", max, filterId));
            }
        });

    // Also set sort order if found.
    if (params.sortOrder) actions.push(setModelResultsOrder(params.sortOrder));

    return actions;
};

const getFilterQueryParam = (filterId: ModelFilterId): ModelFilterQueryParam | null => {
    let result: ModelFilterQueryParam | null = null;

    Object.entries(modelFilterQueryParamsMap).forEach((entry) => {
        if (entry[1] === filterId) result = entry[0] as ModelFilterQueryParam;
    });

    return result;
};

type ParamsType = { queryString: ModelFilterParamType; localStorage: SelectedFilterLocalStorageType };

type LocalStorageArrayType = string[];
type LocalStorageMinMaxType = {
    min: number;
    max: number;
    type?: string;
};
export type SelectedFilterLocalStorageType = Partial<{
    [LocalStorageFilterKey.OnlineSales]: LocalStorageArrayType;
    [LocalStorageFilterKey.Equipment]: LocalStorageArrayType;
    [LocalStorageFilterKey.Seats]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.Doors]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.PowerOutput]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.Price]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.Transmission]: LocalStorageArrayType;
    [LocalStorageFilterKey.FuelType]: LocalStorageArrayType;
    [LocalStorageFilterKey.CarType]: LocalStorageArrayType;
    [LocalStorageFilterKey.FuelConsumption]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.Speed]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.Luggage]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.Co2]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.Eta]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.TowingCapacity]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.LoadVolume]: LocalStorageMinMaxType;
    [LocalStorageFilterKey.MaximumPayload]: LocalStorageMinMaxType;
}>;

/**
 * Convert the given modelFilters state into a param object.
 * Always make sure that the filters object contains the filters for the provided context!
 */
export const modelFilterValuesToParams = (filters: ModelFiltersReducerType): ParamsType => {
    const queryString: ParamsType["queryString"] = {};
    const localStorage: ParamsType["localStorage"] = {};
    Object.values(ModelFilterId)
        .filter((filterId) => filters[filterId]?.active)
        .forEach((filterId) => {
            const queryParam = getFilterQueryParam(filterId);
            if (!queryParam) return;

            if (isCarType(filterId)) {
                const { carType, fuelType } = getSelectedCarTypes(filters[filterId]!, "id");
                if (fuelType.length) {
                    queryString.fuelType = fuelType.join(",");
                    localStorage.fuelType = [...fuelType];
                }
                if (carType.length) {
                    queryString.carType = carType.join(",");
                    localStorage.carType = [...carType];
                }
            } else if (isPrice(filterId)) {
                if (filters[filterId]?.cash.active) {
                    const { currentMinValue, currentMaxValue } = filters[filterId]!.cash;
                    queryString.price = `cash:${currentMinValue}-${currentMaxValue}`;
                    localStorage.price = {
                        min: currentMinValue,
                        max: currentMaxValue,
                        type: "cash",
                    };
                } else if (filters[filterId]?.monthly.active) {
                    const { currentMinValue, currentMaxValue } = filters[filterId]!.monthly;
                    queryString.price = `monthly:${currentMinValue}-${currentMaxValue}`;
                    localStorage.price = {
                        min: currentMinValue,
                        max: currentMaxValue,
                        type: "monthly",
                    };
                }
            } else if (isMultipleChoice(filterId)) {
                const { values } = filters[filterId]!;
                const valueIds = values.filter((filterValue) => filterValue.selected).map((value) => value.id);
                queryString[queryParam] = valueIds.join(",");

                localStorage[localStorageModelFilterMap[filterId]] = valueIds;
            } else if (isMinMaxSlider(filterId)) {
                const { currentMinValue, currentMaxValue } = filters[filterId]!;
                queryString[queryParam] = `${currentMinValue}-${currentMaxValue}`;

                localStorage[localStorageModelFilterMap[filterId]] = {
                    min: currentMinValue,
                    max: currentMaxValue,
                };
            }
        });

    return { queryString, localStorage };
};
