import React from "react";
import DropdownContainer from "../../../../../../common-deprecated/components/dropdown/DropdownContainer";
import { ToggleIconWrapper } from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { IconChevronDown } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import * as Type from "../../../../../../common-deprecated/styles/v2/toyota/globals/Type";
import * as Styles from "./styles/FilterDropdownStyles";
import useFilterDropDown from "../../../../../../shared-logic/features/filters/hooks/useFilterDropDown";
import { ModelFilterLabel } from "../../../utils/labels";
import SrOnly from "../../../../../../common-deprecated/styles/v2/toyota/utilities/ScreenreaderOnly";

type FilterDropdownType = {
    onClick: () => void;
    show: boolean;
    title: string;
    children: React.ReactNode;
};

const FilterDropdown = (props: FilterDropdownType): JSX.Element => {
    const { show, onClick, title, children } = props;
    const { propagatedShow, renderDropdownContainer } = useFilterDropDown(show);

    return (
        <Styles.ListItem role="listitem">
            <Styles.Wrapper open={propagatedShow}>
                <Styles.Header onClick={onClick} show={propagatedShow} role="button">
                    <SrOnly>
                        <ModelFilterLabel label="ariaFilter" />:
                    </SrOnly>
                    <Type.Text as="h4" headingType="zeta" styleType="text">
                        {title}
                    </Type.Text>
                    <ToggleIconWrapper isDown={propagatedShow} aria-hidden="true">
                        <IconChevronDown />
                    </ToggleIconWrapper>
                </Styles.Header>
                {renderDropdownContainer && (
                    <DropdownContainer show={propagatedShow} scrollIntoView>
                        <Styles.DropdownContent>{children}</Styles.DropdownContent>
                    </DropdownContainer>
                )}
            </Styles.Wrapper>
        </Styles.ListItem>
    );
};

export default FilterDropdown;
