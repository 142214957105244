import { RefObject, useLayoutEffect, useState } from "react";
import { PosType } from "../../../../common-deprecated/types/CommonTypes";

const useFilterPopup = (
    filterRef: RefObject<HTMLElement>,
    containerRef: RefObject<HTMLElement>,
    originalPos: PosType,
): PosType => {
    const [position, setPosition] = useState<PosType>({ x: 0, y: 0 });

    // Calculate the correct position for the filter item.
    // LayoutEffect is required here because we need to calculate position before actually rendering.
    useLayoutEffect(() => {
        const containerRect = containerRef.current ? containerRef.current.getBoundingClientRect() : null;
        const filterRect = filterRef.current ? filterRef.current.getBoundingClientRect() : null;

        if (containerRect && filterRect) {
            let actualX = originalPos.x;
            // Add some padding to prevent the popup of covering the button.
            // TODO Should this be dynamically be calculated? If so we'd need data on the height of the button.
            const actualY = originalPos.y - containerRect.top + 10;
            if (originalPos.x + filterRect.width > containerRect.right) {
                actualX = containerRect.right - filterRect.width;
            }

            // Make sure to take the left position of the parent container into account.
            // Assuming that the FilterPopup is absolute positioned inside the container we have to subtract the left position of the container to get correct placement on wide screens.
            setPosition({ x: actualX - containerRect.left, y: actualY });
        } else {
            setPosition({ x: 0, y: 0 });
        }
    }, [originalPos.x, originalPos.y, containerRef, filterRef]);

    return position;
};

export default useFilterPopup;
